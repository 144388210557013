import {MDBContainer} from 'mdbreact';
import React from 'react';
import {Doughnut} from 'react-chartjs-2';

export type DonutChartPropsV = {
  donutchartdata: any;
};

const DonutChartV = (props: DonutChartPropsV) => {
  return (
    <MDBContainer>
      <h3>{props?.donutchartdata?.title}</h3>
        <Doughnut
          data={props?.donutchartdata}
          options={{responsive: true}}
          height={props?.donutchartdata?.height}
          legend={{position:"bottom"}}

        />

    </MDBContainer>
  );
};

export default DonutChartV;

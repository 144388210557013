// @ts-nocheck
import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {AvatarV} from 'react-native-avatar-vfw';
import {Panel} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import Divider from 'rsuite/lib/Divider';
import {cardVprops} from '../VerifyCardV';
import Icon from 'rsuite/lib/Icon';
const PRIMARY_COLOR = '#88AECB';

export const MobileViewV = (props: cardVprops) => {
  const {
    productData,
    verifyData,
    appearance,
    imgStyle,
    contentPos,
    panelStyle,
    titleStyle,
    subTitleStyle,
    labelPosStyle,
    imgHeight,
    labelStyle,
    valueStyle,
    iconStyles,
    primaryColor,
  } = props;

  const styles = {
    containerStyle: {
      justifyContent: 'center',
      // marginTop: '50px',
    },
    titleStyle: {
        marginTop:20,
        fontSize: '24px',
        fontWeight: '500',
        wordWrap: 'break-word',
        justifyContent: 'center',
        textAlign: 'center',
    },
    subTitleStyle: {
      paddingBottom: 15,
      fontSize: 16,
    },
    iconStyle: {
      paddingRight: 10,
      fontSize:18,
      paddingTop:5
    },
    labelStyle: {
      fontWeight: '500',
      fontSize: '14px',
      textAlign: 'left',
      paddingRight: '100px',
      display:'block',
      paddingLeft:20,
      paddingRight:20
    },
    valueStyle:{
      fontSize: '12px',
      // width: '420px',
      wordWrap: 'break-word',
      display:'block',
      paddingLeft:20,
      paddingBottom:20,
      paddingRight:20
    },
    panelStyle:{
      backgroundColor: props?.primaryColor || PRIMARY_COLOR,
      padding:20
    }
  };

  const data: any = [];
  const getContent = () => {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(verifyData) &&
      verifyData.forEach(item => {
        data.push(
          <View style={{display: 'flex'}}>
            <div style={StyleSheet.flatten([labelPosStyle])}>
              <Text style={StyleSheet.flatten([labelStyle, styles.labelStyle])}>
                {item.label}
              </Text>
              {
              item?.link ? (
                <a href={item?.link} style={{textDecoration: 'underline'}}>
                  <Text style={StyleSheet.flatten([styles.valueStyle])}>
              {item?.value}
            </Text>
                </a>
              ):(
                <Text style={StyleSheet.flatten([styles.valueStyle])}>
              {item?.value}
            </Text>
              )
            }
            </div>
          </View>,
        );
      });
    return data;
  };
  const output: any = [];
  const getCard = () => {
    // eslint-disable-next-line no-unused-expressions
    return (
      <View>
        <Panel
          shaded
          bordered
          bodyFill
          style={{borderRadius:25}}
          // style={StyleSheet.flatten([styles.panelStyle])}
        >
          <Panel style={StyleSheet.flatten([styles.panelStyle])}>
            <View style={StyleSheet.flatten([imgStyle, styles.imgStyle])}>
              <AvatarV
                source={{uri: productData?.image}}
                avatarStyle={{resizeMode: 'contain'}}
                containerStyle={{
                  width: '100%',
                  height: 200,
                }}
              />
            </View>
            </Panel>
            <View style={StyleSheet.flatten([contentPos])}>
              <Text style={StyleSheet.flatten([titleStyle, styles.titleStyle])}>
                {productData?.title}
              </Text>
              {productData?.link ? (
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  <Icon
                    style={StyleSheet.flatten([iconStyles, styles.iconStyle])}
                    icon="globe"
                    size={'2x'}
                  />
                  <a
                    href={productData?.link}
                    style={{textDecoration: 'underline'}}
                  >
                    <Text
                      style={StyleSheet.flatten([
                        subTitleStyle,
                        styles.subTitleStyle,
                      ])}
                    >
                      {productData?.subTitle}
                    </Text>
                  </a>
                </View>
              ) : (
                <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                }}
              >
                <Icon
                  style={StyleSheet.flatten([iconStyles, styles.iconStyle])}
                  icon="globe"
                  size={'2x'}
                />
                  <Text
                    style={StyleSheet.flatten([
                      subTitleStyle,
                      styles.subTitleStyle,
                    ])}
                  >
                    {productData?.subTitle}
                  </Text>
              </View>
              )}
              <Divider style={{backgroundColor:props?.primaryColor || PRIMARY_COLOR, padding:2}}/>
              {/* <hr style={StyleSheet.flatten([hrLineStyle])} /> */}
              {getContent()}
            </View>
        </Panel>
      </View>
    );
  };
  return <View>{getCard()}</View>;
};

//@ts-nocheck
import React, {ReactNode} from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';
import {AvatarV} from 'react-native-avatar-vfw';
import {TextV} from 'react-native-text-vfw';
import 'rsuite/dist/styles/rsuite-default.css';
import Icon from 'rsuite/lib/Icon';
import TimeLine from 'rsuite/lib/TimeLine';

const ids = require('short-id')

type IconPropsInternal = {
  style?: any;
  name: string | any;
  stack?: '1x' | '2x';
  componentClass?: any;
  fixedWidth?: boolean;
  flip?: 'horizontal' | 'vertical';
  inverse?: boolean;
  pulse?: boolean;
  rotate?: boolean;
  size?: 'lg' | '2x' | '3x' | '4x' | '5x';
  spin?: boolean;
  svgStyle?: any;
  iconStyle?: any;
};

type Paragraphs = {
  paragraph?: string;
  style?: TextStyle;
  key?: string;
  numberOfLines?: number;
  align: 'auto' | 'left' | 'right' | 'center' | 'justify';
  color: string;
  type:
  | 'body'
  | 'callout'
  | 'caption'
  | 'caption'
  | 'footnote'
  | 'headline'
  | 'subhead'
  | 'title1'
  | 'title2'
  | 'title3';
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
}

type TimeLineItem = {
  classPrefix?: string;
  componentClass?: any;
  time?: string;
  timeTextStyle?: {
    numberOfLines?: number;
    align: 'auto' | 'left' | 'right' | 'center' | 'justify';
    color: string;
    type:
    | 'body'
    | 'callout'
    | 'caption'
    | 'caption'
    | 'footnote'
    | 'headline'
    | 'subhead'
    | 'title1'
    | 'title2'
    | 'title3';
    ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
  };
  dotIcon?: IconPropsInternal;
  dotAvatar?: string;
  style?: any;
  key?: any;
  item?: Paragraphs[];
};
export type WidgetProps = {
  align?: 'left' | 'right' | 'alternate';
  classPrefix?: string;
  componentClass?: any;
  endless?: boolean;
  items: TimeLineItem[];
  width?: number | string;
  height?: number | string;
  timeLineStyle?: any;
  iconContainerStyle?: any;
  containerStyle?: ViewStyle;
  renderDot?(key?: any, item?: TimeLineItem): ReactNode;
  renderTime?(key?: any, item?: TimeLineItem): ReactNode;
  renderItem?(item?: TimeLineItem, index?: number): ReactNode;
}

const getTextAlign = (align?: 'left' | 'right' | 'alternate') => {
  if (align) {
    if (align === 'alternate') {
      return 'right';
    }
    return align;
  }
  return 'center';
}

export const TimeLineV = (props: WidgetProps) => {
  const styles = StyleSheet.create({
    timeLineStyle: {
      marginLeft: '20px',
    },
    iconContainerStyle: {
      position: 'absolute',
      backgroundColor: ' #fff',
      top: 0,
      left: -2,
      // border: '2px solid #ddd',
      width: 40,
      height: 40,
      borderRadius: '50%',
      borderWidth: 2,
      borderColor: '#ddd',
      fontSize: 18,
      paddingTop: 9,
      color: '#999',
      marginLeft: -13,

    },
    iconStyle: {},

    containerStyle: {
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'auto',
    },
    paragraghContainer: {
      width: '92%',
    },
    paragraphStyle: {
      textAlign: getTextAlign(props.align),
    },
    timelineText: {},
    timeContainer: {
      width: 100,
      alignItems: 'center',
      justifyContent: 'center',
    },
    dotAvatarContainerStyle: {
      position: 'absolute',
      top: 0,
      left: -2,
      width: 40,
      height: 40,
      paddingTop: 9,
      marginLeft: -13,
      borderRadius: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
    dotAvatarStyle: {
      width: 32,
      height: 32,
      borderRadius: '50%',
      borderColor: '#ddd',
      borderWidth: 2,
    },
  });

  const renderTimelinteItemText = (element: TimeLineItem, timelineIndex) => {
    const timeline = [];
    element.item &&
      Array.isArray(element.item) &&
      element.item?.forEach((item: Paragraphs, index: number) => {
        timeline.push(

          <TextV
            type={item.type}
            ellipsizeMode={item.ellipsizeMode}
            numberOfLines={item.ellipsizeMode}
            color={item.color}
            key={ids.generate()}
            style={StyleSheet.flatten([
              styles.paragraphStyle,
              item.style,
              props.align === 'alternate' && {
                textAlign: timelineIndex % 2 === 0 ? 'left' : 'right',
              },
              {
                marginLeft:
                  props.align === 'left'
                    ? 18
                    : props.align === 'alternate' && timelineIndex % 2 === 0
                      ? 18
                      : 0,
                marginTop: index === 0 && (element.dotAvatar || element.dotIcon) ? 10 : 0,
                marginRight:
                  props.align === 'right'
                    ? 18
                    : props.align === 'alternate' && timelineIndex % 2 !== 0
                      ? 18
                      : 0,
              },
            ])}
            align={item.align}
          >
            {item.paragraph}
          </TextV>
           ,
        );
      });
    return timeline;
  }

  const _getSingleTimeLine = (element: TimeLineItem, timelineIndex: number) => {
    if (props.renderItem && element) {
      return props.renderItem(element, timelineIndex);
    }
    return (
      <View style={StyleSheet.flatten([styles.paragraghContainer])}>
        {renderTimelinteItemText(element, timelineIndex)}
      </View>
    );
  };

  const _getIcons = icon => {
    return (
      <Icon
        icon={icon.name}
        size={icon.size}
        style={StyleSheet.flatten([styles.iconContainerStyle, icon.iconStyle, {border: '2px solid #ddd'}])}
      />
    );
  };

  const renderDots = (element: TimeLineItem) => {
    if (props.renderDot && element) {
      return props.renderDot(element.key, element);
    }
    if (element.dotIcon) {
      return _getIcons(element.dotIcon)
    }
    if (element.dotAvatar) {
      return (
        <AvatarV
          source={element.dotAvatar}
          rounded
          avatarStyle={StyleSheet.flatten([styles.dotAvatarStyle])}
          containerStyle={StyleSheet.flatten([styles.dotAvatarContainerStyle])}
        />
        // <img
        //   src={element.dotAvatar.source}
        //   style={StyleSheet.flatten([styles.dotAvatarContainerStyle])}
        // />
      );
    }
    return undefined;
  }

  const renderTimeInternal = (element: TimeLineItem, timelineIndex: number) => {
    if (props.renderTime && element) {
      return props.renderTime(element.key, element);
    }
    if (element.time) {
      return (
        <TextV
          style={StyleSheet.flatten([
            styles.timelineText,
            {
              marginLeft:
                props.align === 'right'
                  ? 12
                  : props.align === 'alternate' && timelineIndex % 2 === 0
                    ? -12
                    : 12,
              marginTop: 10,
              marginRight:
                props.align === 'left'
                  ? 12
                  : props.align === 'alternate' && timelineIndex % 2 !== 0
                    ? -12
                    : 12,
            },
          ])}
          type={element.timeTextStyle?.type ?? 'body'}
          color={element.timeTextStyle?.color ?? 'rgba(0,0,0,0.6)'}
          align={element.timeTextStyle?.align ?? 'auto'}
          numberOfLines={element.timeTextStyle?.numberOfLines ?? 1}
          ellipsizeMode={element.timeTextStyle?.ellipsizeMode ?? 'tail'}
        >
          {element.time}
        </TextV>
      );
    }
    return null;
  };

  const _getItems = () => {
    const timeLine = [];
    props.items &&
      props.items.forEach((element: TimeLineItem, index: number) => {
        timeLine.push(
          <TimeLine.Item
            classPrefix={element.classPrefix}
            componentClass={element.componentClass}
            dot={renderDots(element)}
            time={renderTimeInternal(element, index)}
            style={StyleSheet.flatten([element.style,])}
            key={ids.generate()}
          >
            {_getSingleTimeLine(element, index)}
          </TimeLine.Item>,
        );
      });
    return timeLine;
  };

  return (
    <View
      style={StyleSheet.flatten([styles.containerStyle, props.containerStyle])}
    >
      <TimeLine
        align={props.align}
        classPrefix={props.classPrefix}
        className="custom-timeline"
        componentClass={props.componentClass}
        style={StyleSheet.flatten([
          styles.timeLineStyle,
          props.timeLineStyle,
          props.width && {width: props.width},
          props.height && {height: props.height},
        ])}
      >
        {_getItems()}
      </TimeLine>
    </View>
  );
};

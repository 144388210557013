//@ts-nocheck
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import React from 'react';
import {StyleSheet, Text} from 'react-native';
import {Icon} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
const styles = {

  cardStyle : {
    padding:"15px",
    textAlign: 'center',
    boxShadow: '10px 15px 15px rgba(100,100,255,0.3)',
  },
  ContentHeaderStyles:{
    display:'flex',
    color:'#f5f5f5',
    fontSize:'50px',
    fontWeight:'600'
  },
  ContentTitleStyles:{
    display:'flex',
    fontWeight:'500',
    color:'white',
    textTransform: 'uppercase',
    fontSize:'20px',
  },
  ContentIconStyles:{
    width:'50px',
    height:'50px',
    float:"right",
    fontSize:"50px",
    color:"#f5f5f5",
    opacity:0.5
  },
 ContentDescriptionStyles:{
    display:'flex',
    fontWeight:'350',
    fontSize:'20px',
    color:'white',
  }
};
type cardprops = {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  cardData?: {
    total?: string;
    title?: string;
    description?: string;
    icon?: string;
    backgroundColor?: string;
  }[];
  titleStyles?: any;
  subtitleStyles?: any;
  ContentHeaderStyles?: any;
  ContentTitleStyles?: any;
  ContentDescriptionStyles?: any;
  ContentIconStyles?: any;
};
const CardDisplayV = (props: cardprops) => {
  const _getCards = () => {
    const _tempData: any = [];
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(props?.cardData) &&
      props?.cardData.forEach(item => {

        _tempData.push(
          <Grid item xs={12} sm={6} md={2}>

            <Paper
                    style={StyleSheet.flatten([styles.cardStyle,{backgroundImage:item?.backgroundColor}])}>
              <Icon
                style={StyleSheet.flatten([
                  styles.ContentIconStyles,
                ])}
                icon={item?.icon}
              />
              <Text
                style={StyleSheet.flatten([
                  styles.ContentTitleStyles,
                ])}
              >
                {item?.title}
              </Text>
              <Text
                style={StyleSheet.flatten([
                  styles.ContentDescriptionStyles,
                ])}
              >
                {item?.description}
              </Text>
              <Text
                style={StyleSheet.flatten([
                  styles.ContentHeaderStyles,
                ])}
              >
                {item?.total}
              </Text>
            </Paper>
          </Grid>,
        );
      });
    return _tempData;
  };
  return (
    <Grid container justify={'center'} alignItems={'center'}>
      <Grid container spacing={3}>
        {_getCards()}
      </Grid>
    </Grid>
  );
};
export default CardDisplayV;

// @ts-nocheck
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {StyleSheet, Text, View} from 'react-native';
import {AvatarV} from 'react-native-avatar-vfw';
import 'rsuite/dist/styles/rsuite-default.css';
import Icon from 'rsuite/lib/Icon';
import Panel from 'rsuite/lib/Panel';
import {cardVprops} from '../VerifyCardV';
import Divider from 'rsuite/lib/Divider';
const PRIMARY_COLOR = '#88AECB';

export const WebViewV = (props: cardVprops) => {
  const {
    productData,
    verifyData,
    appearance,
    iconsStyles,
    imgStyle,
    contentPos,
    panelStyle,
    titleStyle,
    subTitleStyle,
    hrLineStyle,
    labelPosStyle,
    imgHeight,
    labelStyle,
    valueStyle,
    imgContainerStyle,
  } = props;

  const styles = {
    containerStyle: {
      display: 'flex',
      flexDirection: 'row',
    },

    imgContainerStyle: {
      backgroundColor: '#88AECB',
      paddingTop: '12%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    titleStyle: {
      fontSize: 22,
      fontWeight: '500',
      paddingLeft: 20,
      paddingBottom: 15,
      marginTop:20,
    },
    rightContainerStyle: {
      paddingLeft: 15,
    },
    subTitleStyle: {
      fontSize: '17px',
      color: 'grey',
      justifyContent: 'center',
      paddingLeft: 10,
      paddingBottom: 15,
    },
    labelStyle: {
      fontWeight: '500',
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: 20,
      paddingBottom: 5,
      display: 'block',
    },
    valueStyle: {
      fontSize: '12px',
      // width: '420px',
      wordWrap: 'break-word',
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      display: 'block',
    },
    panelStyle:{
      borderRadius:30
    }
  };
  const data: any = [];
  const getContent = () => {
    Array.isArray(verifyData) &&
      verifyData.forEach(items => {
        data.push(
          <>
            <Text style={StyleSheet.flatten([styles.labelStyle])}>
              {items?.label}
            </Text>
            {
              items?.link ? (
                <a href={items?.link} style={{textDecoration: 'underline'}}>
                  <Text style={StyleSheet.flatten([styles.valueStyle])}>
              {items?.value}
            </Text>
                </a>
              ):(
                <Text style={StyleSheet.flatten([styles.valueStyle])}>
              {items?.value}
            </Text>
              )
            }
            
          </>,
        );
      });
    return data;
  };

  const output: any = [];
  const getCard = () => {
    return (
      <Panel shaded bodyFill style={StyleSheet.flatten([styles.panelStyle])}>
        <Grid container style={StyleSheet.flatten([styles.containerStyle])}>
          <Grid
            item
            sm={4}
            style={StyleSheet.flatten([styles.imgContainerStyle])}
          >
            <AvatarV
              source={{uri: productData?.image}}
              avatarStyle={{resizeMode: 'contain'}}
              containerStyle={{
                width: '100%',
                height: 200,
                // alignItems: 'center',
                // justifyContent: 'center',
              }}
            />
          </Grid>
          <Grid item sm={8} >
            <View style={{paddingTop:20}}>
            <Text style={StyleSheet.flatten([titleStyle, styles.titleStyle])}>
              {productData?.title}
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <Icon icon="globe" size={'2x'} style={{paddingLeft: 20, paddingTop:5, fontSize:20}} />
              <a href={productData?.link} style={{textDecoration: 'underline'}}>
                <Text
                  style={StyleSheet.flatten([
                    subTitleStyle,
                    styles.subTitleStyle,
                  ])}
                >
                  {productData?.subTitle}
                </Text>
              </a>
            </View>
            </View>
            <Divider style={{backgroundColor:props?.primaryColor || PRIMARY_COLOR, padding:3}}/>
            {getContent()}
           
          </Grid>
        </Grid>
      </Panel>
    );
    // return (
    //   <Panel shaded bordered bodyFill style={{alignItems: 'center'}}>
    //     <View style={{alignItems: 'center'}}>
    //       <Grid container justify={'center'} alignItems="stretch">
    //         <Grid
    //           item
    //           sm={4}
    //           style={StyleSheet.flatten([styles.imgContainerStyle])}
    //         >
    //           <View style={{alignItems: 'center', display: 'block'}}>
    //             <AvatarV
    //               source={{uri: productData?.image}}
    //               avatarStyle={{resizeMode: 'contain'}}
    //               containerStyle={{
    //                 width: '100%',
    //                 height: 200,
    //               }}
    //             />
    //           </View>
    //         </Grid>
    //         <Grid item sm={8}>
    //           <View
    //             style={StyleSheet.flatten([
    //               contentPos,
    //               styles.rightContainerStyle,
    //             ])}
    //           >
    //             <Text
    //               style={StyleSheet.flatten([titleStyle, styles.titleStyle])}
    //             >
    //               {productData?.title}
    //             </Text>
    //             <View
    //               style={{
    //                 display: 'flex',
    //                 flexDirection: 'row',
    //                 alignItems: 'flex-start',
    //                 justifyContent: 'flex-start',
    //               }}
    //             >
    //               <Icon icon="globe" size={'2x'} />
    //               <a
    //                 href={productData?.link}
    //                 style={{textDecoration: 'underline'}}
    //               >
    //                 <Text
    //                   style={StyleSheet.flatten([
    //                     subTitleStyle,
    //                     styles.subTitleStyle,
    //                   ])}
    //                 >
    //                   {productData?.subTitle}
    //                 </Text>
    //               </a>
    //             </View>
    //             {getContent()}
    //           </View>
    //         </Grid>
    //       </Grid>
    //     </View>
    //   </Panel>
    // );
  };
  return (
    <FBGridV justify={'center'} align={'center'}>
      <FBItemV colspan={24}>{getCard()}</FBItemV>
    </FBGridV>
  );
};

import React from 'react';
import { View, StyleSheet, ScrollView, Text} from 'react-native';
import { BodyTemplateV } from 'react-body-template-1';
import { useViewport } from 'react-viewport-provider';
import { DrawerV } from 'react-drawer-rs';
import Button from 'rsuite/lib/Button';
import {StepTemplateV} from 'react-step-rs-template';
import {InputV} from 'react-input-rs';
import { SelectInputV } from 'react-select-input-rs';
import {InputNumberV} from '../../../../../../widgets/InputNumberV';
import {FormGeneratorV, HttpServer} from '../../../../../../widgets/FormBuildGenV/src';
import {ThemeContext} from '../../../../../../providers/theme';
import blueprint from './config/default-blueprint.json';
import {Preview} from '../preview';

const BP = blueprint.addProvenance;
const http = new HttpServer();

interface addProvenanceProps {
    onCreateProvenanceSubmit?(formData: object): void;
}

export const AddProvenance = (props: addProvenanceProps) => {
    let name:string;
    let gtinValue: string;
    let steps:number;
    const [formData, setFormData] = React.useState<object>({
        provenanceSteps: [],
        gtin: ''
    });
    const [provenanceSteps, setProvenanceSteps] = React.useState([]);
    const {height, width} = useViewport();
    const theme = React.useContext(ThemeContext);
    const parentRef = React.useRef(0);
    const [enable, setEnable] = React.useState(true);
    const [step, setStep] = React.useState<number>(0);
    const [data, setData] = React.useState<any>();
    const [initialFormData, setInitialFormData] = React.useState<object>({});
    const [provenanceData, setProvenanceData] = React.useState();
    const [totalSteps, setTotalSteps] = React.useState();
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'md',
        backdrop: false,
        show: false,
    });
    React.useEffect(() => {
        http.get('http://vlinderserver.online:3014/formsuite?cid=tragAddProvenance')
            .then(res => {
                if (res?.data) {
                    setData(res?.data?.form?.task_data);
                }
            })
    }, [])

    React.useEffect(() => {
        console.log(`provenance data in useEffect`, provenanceData);
        if(!!provenanceData){
            _submitFormData();
        }
    }, [provenanceData])

    if(!totalSteps){
        setTotalSteps(BP.stepItems);
    }
    const styles = {
        mainContainerStyle: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
            backgroundColor: '#f7f7fa',
            borderRadius: 'none',
            padding: 20,
        },
        containerStyle: {
            width: 500,
            borderWidth: 2,
            borderRadius: 10,
            borderColor: '#d2d3d3',
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 60,
            boxShadow: "1px 3px 1px #d2d3d3",
        },
        inputStepsStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        }
    }

    const _submitFormData = () => {
        let res: object = {};
        res.images = [];
        provenanceData && 
            Array.isArray(provenanceData) && provenanceData.forEach((element, index) => {
                console.log(`image URL`, element?.value)
                if (element?.key === 'title' && !!element?.value) {
                    res.title = element?.value
                } else if (element?.key === 'description' && !!element?.value) {
                    res.descriptionLine1 = element?.value
                } else if (element?.key === 'address' && !!element?.value) {
                    res.address = element?.value
                } else if (element?.key === 'latitude' && !!element?.value) {
                    res.latitudeDelta = element?.value
                } else if (element?.key === 'longitude' && !!element?.value) {
                    res.longitudeDelta = element?.value
                } else if (element?.key === 'stepImage1' && !!element?.value) {
                    res.images.push(element.value)
                } else if (element?.key === 'stepImage2' && !!element?.value) {
                    res.images.push(element.value)
                } else if (element?.key === 'stepImage3' && !!element?.value) {
                    res.images.push(element.value)
                } else if (element?.key === 'stepImage4' && !!element?.value) {
                    res.images.push(element.value)
                } else if (element?.key === 'stepImage5' && !!element?.value) {
                    res.images.push(element.value)
                }
            });
        console.log(`sructured provenance Data`, res);
            setProvenanceSteps([...provenanceSteps, res])
    }

    const handleSubmit = (data: any) => {
        console.log(`provenance Step formData`, data)
        setTimeout(function () {
            setProvenanceData(data);
         }, 3000)
    }
    const bodyContent = () => {
        // return (
        //     <View style={{justifyContent: 'center', alignItems: 'center'}}>
        //         <Preview />
        //     </View>
        // );
        return null
    }

    const onNavItemClick = (eventKey: string) => {
        //setUserActionKey(eventKey);
        // if(eventKey === 'addProvenance'){
        //     let _drawer = { ...drawer };
        //     _drawer.placement = BP.notificationsDrawerPlacement;
        //     _drawer.show = true;
        //     setDrawer({
        //         ..._drawer,
        //     });
        // }
        // if (eventKey === 'newIssuer') {
        //   let _drawer = { ...drawer };
        //   _drawer.title = humanizeString(eventKey);
        //   _drawer.placement = BP?.drawerPlacement;
        //   _drawer.show = true;
        //   setDrawer({
        //     ..._drawer,
        //   });
        // }
        
    };


    const _getModalFooter = () => {
        return null;
    }

    // <View style={StyleSheet.flatten([styles.mainContainerStyle])}>
    //     {data &&
    //         <FormGeneratorV
    //             back_action="/"
    //             back_name="Back"
    //             action_name="Submit"
    //             form_action="/"
    //             form_method="POST"
    //             onSubmit={handleSubmit}
    //             data={data && data}
    //             containerStyle={StyleSheet.flatten([styles.containerStyle])}
    //         />
    //     }
    // </View>

    const renderContent = (key: string) => {
        console.log(`key value`, key)
        console.log(`totalSteps`, totalSteps)
        if (key === 'addSteps') {
            return (
                <>
                {/* <View style={StyleSheet.flatten([styles.inputStepsStyle])}>
                    <InputV 
                        onChange={(value) => {name = value}}
                        // onEnterPress={_handleEnterPress}
                        title={BP?.inputNameField?.label}
                        {...BP.inputNameField.componentProps}
                    />
                </View> */}
                <View style={StyleSheet.flatten([styles.inputStepsStyle])}>
                    <InputV
                        onChange={(value) => { gtinValue = value }}
                        // onEnterPress={_handleEnterPress}
                        title={BP?.inputGTINField?.label}
                        {...BP.inputGTINField.componentProps}
                    />
                </View>
                <View style={StyleSheet.flatten([styles.inputStepsStyle])}>
                    <InputNumberV
                        onChange={(value) => {steps = value}}
                        // onEnterPress={_handleEnterPress}
                        title={BP?.inputStepField?.label}
                        {...BP.inputStepField.componentProps}
                    />
                </View>
                </>
            );
        } else if (key === 'info') {
            <Text>Fill the forms in next step</Text>
        }else if (initialFormData.steps) {
            let _totalSteps = initialFormData.steps && initialFormData.steps;
            for(let j = 1; j <= _totalSteps ; j++){
                if(key === `form${j}`){
                    return (
                        <ScrollView>
                            <View style={StyleSheet.flatten([styles.mainContainerStyle])}>
                                {data &&
                                    <FormGeneratorV
                                        // back_action="/"
                                        // back_name="Back"
                                        action_name="Save"
                                        form_action="/"
                                        form_method="POST"
                                        onSubmit={handleSubmit}
                                        data={data && data}
                                        //containerStyle={StyleSheet.flatten([styles.containerStyle])}
                                        containerStyle={{ width: 500, borderWidth: 2, borderRadius: 10, borderColor: '#d2d3d3', paddingLeft: 40, paddingRight: 40, paddingBottom: 60, boxShadow: "1px 3px 1px #d2d3d3" }}
                                        submitButtonContainerStyle={{ borderRadius: 6, backgroundColor: '#F05776', marginTop: 30 }}
                                        backButtonContainerStyle={{ borderRadius: 6, marginTop: 30 }}
                                        backButtonTextStyle={{ color: '#0073FF' }}
                                        submitButtonTextStyle={{ color: '#fff', textAlign: 'center', alignSelf: 'center' }}
                                    />
                                }
                            </View>
                        </ScrollView>
                    );
                }
            }
            // let _formData = formData;
            // let i = 0;
            // let forms = [];
            // console.log(`steps`, _formData?.steps)
            //     for(i ; i < _formData?.steps; i++){
            //     i = i + 1;
            //     console.log(`entered`, i)
            //     forms.push(
                    
            //             <View style={StyleSheet.flatten([styles.mainContainerStyle])}>
            //                 {data &&
            //                     <FormGeneratorV
            //                         back_action="/"
            //                         back_name="Back"
            //                         action_name="Submit"
            //                         form_action="/"
            //                         form_method="POST"
            //                         onSubmit={handleSubmit}
            //                         data={data && data}
            //                         containerStyle={StyleSheet.flatten([styles.containerStyle])}
            //                     />
            //                 }
            //             </View>
                   
            //     );
            // }
            // return <ScrollView>{forms}</ScrollView>;
        }
    }

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const _handleNextStep = (currentStep: number, currentKey: string) => {
        console.log(`gtin::`, gtinValue, ': strps:', steps)
        if (currentKey === 'addSteps'){
            if (!!gtinValue && !!steps){
                let _formData = initialFormData;
                _formData.gtin = gtinValue;
                _formData.steps = steps;
                setFormData({ gtin: gtinValue })
                setInitialFormData(_formData);
                setStep(currentStep + 1);
                if(steps > 1){
                    let _totalSteps = totalSteps;
                    for(let i = 1; i < steps; i++){
                        _totalSteps.push(
                            {
                                title: `Form ${i + 1}`,
                                key: `form${i + 1}`,
                                icons: {
                                    name: "user",
                                    iconStyle: {}
                                }
                        })
                    }
                }
            }
        } else {
            setStep(currentStep + 1);
        }
    }

    const _handleOnComplete = () => {
        formData.provenanceSteps = provenanceSteps;
        console.log(`complete  Data :`, formData);
        props?.onCreateProvenanceSubmit && props?.onCreateProvenanceSubmit(formData);
    }

    const renderDrawerBody = () => {
        return (
            <StepTemplateV
                height={'87vh'}
                step={step}
                bordered
                shaded
                items={totalSteps}
                renderStepperContent={renderContent}
                stepIconOnly={true}
                stepperColSpan={2}
                headerTitleToDisplay={true}
                stepperBackgroundColor={'#5987AF'}
                stepperIconActiveColor={'#f05776'}
                onNext={(currentStep, currentKey) => {
                    console.log('onNext current step', currentStep);
                    console.log('current key', currentKey);
                    console.log('parentRef key', parentRef);
                    _handleNextStep(currentStep, currentKey)
                }}
                onPrev={(currentStep, currentKey) => {
                    console.log('onPrev current step', currentStep);
                    console.log('current key', currentKey);
                    console.log('parentRef key', parentRef);
                    if (currentStep > 1) {
                        setStep(currentStep - 1);
                    }
                }}
                onStepperDonePress={() => {_handleOnComplete()}}
                loading={false}
                stepperPrevTitle={'Previous'}
                stepperNextTitle={'Next'}
                enableNextonCurrentStep={enable}
                enablePrevonCurrentStep={enable}
            />
        );
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    return (
        <>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                dashboardContainerStyle={StyleSheet.flatten([styles.containerStyle])}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                //   navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
}
//@ts-nocheck
import styled from "styled-components";

export const Button = styled.button`
    background: ${props => props.color || "#2089DC"};
    color: ${props =>
        props.secondaryColor ? `${props.secondaryColor}` : "#fff"};
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
    border: ${props => (props.color ? `1px solid ${props.color}` : "0px")};
    border-radius: 8px;
    height: 40px;
    outline: none;
    cursor: not-allowed;
    margin-top: 0.625rem;
    max-width: 180px;

    @media only screen and (max-width: 1024px) {
        width: ${props => (props.width ? "160px" : "100%")};
    }

    @media only screen and (max-width: 768px) {
        width: ${props => (props.width ? "140px" : "100%")};
    }

    @media only screen and (max-width: 480px) {
        width: ${props => (props.width ? "130px" : "100%")};
    }

    &:hover,
    &:active,
    &:focus {
        color: ${props =>
            props.onFocusColor ? `${props.onFocusColor} ` : "#fff"} !important ;
        border: ${props =>
            props.secondaryColor ? "1px solid #00235a" : "0px"};
        background-color: ${props => props.secondaryColor || "#00235a"};
    }
`;

import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import BarChart from './BarChart';
import CardDisplayV from './cards';
import DonutChartV from './donutChart';
export type dashBoardProps = {
    graphData?: any;
};
const Mystyle = {
    backgroundColor: {
        backgroundColor: '#eee',
    },
    chartstyle: {
        backgroundColor: 'white',
        marginLeft: '65px',
        borderRadius: '5px',
        padding: '10px',
    },
    timeline: {
        borderRadius: '5px',
    },
    containerPos: {
        marginLeft: '-45px',
    },
};
export const Charts = (props: dashBoardProps) => {
    const getcard = () => {
        const output: any = [];
        Array.isArray(props?.graphData) &&
            props?.graphData.forEach(items => {
                if (items?.type === 'cardData')
                    output.push(
                        <Grid container style={StyleSheet.flatten([items?.styles])}>
                            <CardDisplayV cardData={items?.data} />
                        </Grid>,
                    );
            });
        return output;
    };
    const _getCharts = () => {
        const output: any = [];
        Array.isArray(props?.graphData) &&
            props?.graphData.forEach(items => {
                if (items?.type === 'barchart-transaction')
                    output.push(
                        <Grid md={items?.mediumDevice} sm={items?.smallDevice}>
                            <Paper style={StyleSheet.flatten([items?.styles])}>
                                <BarChart
                                    BarChartData={items?.data}
                                    barChartOptions={items?.data?.barChartOptions}
                                />
                            </Paper>
                        </Grid>,
                    );
                else if (items?.type === 'barchart-country')
                    output.push(
                        <Grid md={items?.mediumDevice} sm={items?.smallDevice}>
                            <Paper style={StyleSheet.flatten([items?.styles])}>
                                <BarChart
                                    BarChartData={items?.data}
                                    barChartOptions={items?.data?.barChartOptions}
                                />
                            </Paper>
                        </Grid>,
                    );
                else if (items?.type === 'barchart-months')
                    output.push(
                        <Grid md={items?.mediumDevice} sm={items?.smallDevice}>
                            <Paper style={StyleSheet.flatten([items?.styles])}>
                                <BarChart
                                    BarChartData={items?.data}
                                    barChartOptions={items?.data?.barChartOptions}
                                />
                            </Paper>
                        </Grid>,
                    );
                else if (items?.type === 'donutchart')
                    output.push(
                        <Grid md={items?.mediumDevice} sm={items?.smallDevice}>
                            <Paper style={StyleSheet.flatten([items?.styles])}>
                                <DonutChartV donutchartdata={items?.data} />
                            </Paper>
                        </Grid>,
                    );
            });
        return output;
    };
    return (
        <View style={StyleSheet.flatten([Mystyle.backgroundColor])}>
            <Grid container>
                {getcard()}
                {_getCharts()}
            </Grid>
        </View>
    );
};


import _ from 'lodash';
import { DataSet } from 'vis-data';
import * as changeCase from "change-case";
const is = require('is_js');
const ID = require('./uuid');
const SDRGenDS = new DataSet({
  fieldId: 'field_key',
});

const TEMPLATE_DATA = [
    {
        "field_key": "logo"
    },
    {
        "field_key": "credentialName"
    },
    {
        "field_key": "lineBreak1",
        bold: false,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        element: "LineBreak",
        //   field_name: "a9d314c5-3fe2-49cf-995c-f99b3a0f2bcd"
        //  id: "A0EB608F-2CD2-45B2-B917-6D25FA0EAF8E"
        italic: false,
        required: false,
        static: true,
        text: "Line Break",    
    },
    {
        field_key: "claims",
        canHaveAlternateForm: true,
        canHaveAnswer: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        dirty: true,
        element: "Checkboxes",
        //  field_name: "checkboxes_0C9ED91C-057C-4B13-8585-654911041854"
       // id: "EC9FE2B0-5DA0-420D-9187-0BFF150E5049"
        inline: true,
        label: "<strong>Claim(s)</strong> ",
        options: [],
        required: true,
        text: "Checkboxes",
        "schemaRule": "ArrayType().minLength(1, 'Choose at least one claim in the credential')"
    },
    {
        field_key: "reason",
        canHaveAlternateForm: true,
        canHaveAnswer: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        dirty: false,
        element: "TextArea",
        //field_name: "text_area_6E29A5D8-68FD-429E-9D0A-7C0B6310F5DB"
        //id: "B8366825-4FD6-468E-B0CC-371E5C14C119"
        label: "<strong>Reason</strong> ",
        required: true,
        "schemaRule": "StringType().maxLength(100, 'The maximum is only 100 characters.')"
    },
    {
         field_key: "essential",
        canHaveAlternateForm: true,
        canHaveAnswer: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        dirty: true,
        element: "RadioButtons",
       // field_name: "radiobuttons_684CA50B-7A19-4CDD-A422-4A8BE2F4BC5C"
       // id: "AC189D2D-EC26-4854-A2B7-A0AE14B1DFE8"
        inline: true,
        label: "<strong>Required</strong> ",
        options: [
            {
               // key: "radiobuttons_option_636D9BB5-263E-4E8C-A2A5-5EB30B78DAB1"
                text: "YES",
                value: "v_yes",
            },
            {
               // key: "radiobuttons_option_2C296CCC-D64B-4D7E-85D2-A982FD52055F"
                text: "NO",
                value: "v_no",
            }
        ],
        required: true,
        text: "Multiple Choice",
        "schemaRule": "ArrayType().isRequired('Required field is mandatory')"
    }, {
        "field_key": "lineBreak2",
        bold: false,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        element: "LineBreak",
        //   field_name: "a9d314c5-3fe2-49cf-995c-f99b3a0f2bcd"
        //  id: "A0EB608F-2CD2-45B2-B917-6D25FA0EAF8E"
        italic: false,
        required: false,
        static: true,
        text: "Line Break",    
    },
    {
        field_key: "issuerList",
        bold: false,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        content: '<span style="font-size: 18px;"><strong>Issuer(s)</strong></span> ',
        dirty: true,
        element: "Label",       
        //   field_name: "a9d314c5-3fe2-49cf-995c-f99b3a0f2bcd"
        //  id: "A0EB608F-2CD2-45B2-B917-6D25FA0EAF8E"
        italic: false,
        required: false,
        static: true,
        text: "Label"
    },
    {
        field_key: "issuerDiD1",
        canHaveAlternateForm: true,
        canHaveAnswer: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        dirty: true,
        element: "TextInput",
        // field_name: "text_input_A1905C4A-EAA3-4FC8-9D51-B6D279B0DA1D"
        // id: "2E3C1E8E-DC11-411A-8909-20DC3D8B7EC9"
        label: "<strong>Issuer 1 DID</strong> ",
        password: false,
        required: false,
        text: "Text Input"
    },
    {
        field_key: "issuerUrl1",
        canHaveAlternateForm: true,
        canHaveAnswer: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        dirty: true,
        element: "TextInput",
        // field_name: "text_input_027BB5A6-D1E0-465F-955D-C4666079D101"
        // id: "91136B63-9E5C-4EDB-8BE7-C0E165334CB9"
        label: "<strong>Issuer 1 Home Page URL</strong> ",
        password: false,
        required: false,
        text: "Text Input",
        schemaRule: "StringType().isURL('Please enter the correct URL address')",
    },
    {
        field_key: "issuerDid2",
        canHaveAlternateForm: true,
        canHaveAnswer: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        dirty: true,
        element: "TextInput",
        //  field_name: "text_input_D26E7CD7-BCB3-4071-AABA-60D86675681E"
        // id: "BFDB95C7-95EC-405A-A93D-85339EFE3BDB"
        label: "<strong>Issuer 2 DID</strong> ",
        password: false,
        required: false,
        text: "Text Input",
        schemaRule: "StringType()"
    },
    {
        field_key: "issuerUrl2",
        canHaveAlternateForm: true,
        canHaveAnswer: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        canHavePageBreakBefore: true,
        canPopulateFromApi: true,
        dirty: true,
        element: "TextInput",
        //field_name: "text_input_079DE5AA-C24F-4D4C-B1FA-69512B260F7A"
        // id: "261D9E10-4D4B-464C-9DB7-AF115BCAF930"
        label: "<strong>Issuer 2 Home Page URL</strong> ",
        password: false,
        required: false,
        text: "Text Input",
        schemaRule: "StringType().isURL('Please enter the correct URL address')",
    }
];

SDRGenDS.update(TEMPLATE_DATA);

export const convertCredentialToSDR = (form: any) => {
    if (form && is.array(form) && form.length > 0) {
        for (let i = 0; i < TEMPLATE_DATA.length; ++i) {
            let _template = TEMPLATE_DATA[i];
            if (_template && _template.field_key) {
                const _element = _.find(form, { field_key: _template.field_key });
                if (_element && _template.field_key === 'logo') {
                    SDRGenDS.updateOnly({
                        field_key: _element.field_key,
                        ..._element,
                    });
                }
                if (_element && _template.field_key === 'credentialName') {
                    SDRGenDS.updateOnly({
                        field_key: _element.field_key,
                        ..._element,
                    });
                }
            }
        }
    }

    let claimVal: any = [];
    for (let i = 0; i < form.length; ++i) {
        let _formElement = form[i];
        if (_formElement?.element === "TextInput" && is.existy(_formElement?.field_key)) {
            claimVal.push(Object.assign({}, {
                value: `v_${_formElement?.field_key}`,
                text: changeCase.capitalCase(_formElement?.field_key),
                key: `checkboxes_option_${ID.uuid()}`,
            }))
        }
    }
    SDRGenDS.updateOnly({
        field_key: "claims",
        field_name: `checkboxes_${ID.uuid()}`,
        id: ID.uuid(),
        options: claimVal,
    });

    SDRGenDS.updateOnly({
        field_key: "reason",
        field_name: `text_area_${ID.uuid()}`,
        id: ID.uuid(),
    });
    let _essentialElement: any = SDRGenDS.get('essential');
    let options: any = [];
    if (_essentialElement.options) {
        _essentialElement.options.forEach((option: any) => {
            options.push({
                ...option,
                key: `radiobuttons_option_${ID.uuid()}`
            })
        })
    }
    SDRGenDS.updateOnly({
        field_key: "essential",
        field_name: `radiobuttons_${ID.uuid()}`,
        id: ID.uuid(),
        options: options,
    });
    SDRGenDS.updateOnly({
        field_key: "essential",
        field_name: `radiobuttons_${ID.uuid()}`,
        id: ID.uuid(),
    });
    SDRGenDS.updateOnly({
        field_key: "lineBreak1",
        field_name: ID.uuid(),
        id: ID.uuid(),
    });
    SDRGenDS.updateOnly({
        field_key: "lineBreak2",
        field_name: ID.uuid(),
        id: ID.uuid(),
    });
    SDRGenDS.updateOnly({
        field_key: "issuerList",
        field_name: ID.uuid(),
        id: ID.uuid(),
    });
    SDRGenDS.updateOnly({
        field_key: "issuerDiD1",
        field_name: `text_input_${ID.uuid()}`,
        id: ID.uuid(),
    });
    SDRGenDS.updateOnly({
        field_key: "issuerUrl1",
        field_name: `text_input_${ID.uuid()}`,
        id: ID.uuid(),
    });
    SDRGenDS.updateOnly({
        field_key: "issuerDid2",
        field_name: `text_input_${ID.uuid()}`,
        id: ID.uuid(),
    });
    SDRGenDS.updateOnly({
        field_key: "issuerUrl2",
        field_name: `text_input_${ID.uuid()}`,
        id: ID.uuid(),
    });
    return SDRGenDS.get();
}
import React, { useContext, useLayoutEffect } from 'react';
import { StyleSheet, ScrollView, View } from 'react-native';
import {useViewport} from 'react-viewport-provider';
import config from '../../config/default-blueprint.json';
import { ThemeContext } from '../../../../../../../../providers/theme';
import { BodyTemplateV } from 'react-body-template-1';
import { DrawerV } from 'react-drawer-rs';
import { CredentialTemplateV } from 'react-credentail-template';
import { FormGeneratorV, HttpServer } from '../../../../../../../../widgets/FormBuildGenV/src';

import {IProps} from '../../index';

const BP = config?.batchProducts;
const http = new HttpServer();

export const BatchProducts = (props: IProps) => {
  let tags = [];
  let infolist = [];
  let images = [];
    const theme = useContext(ThemeContext);
    const { width, height } = useViewport();
    // const [data, setData] = React.useState<any>([]);
    const [userActionKey, setUserActionKey] = React.useState('');
  const [data, setData] = React.useState<any>();
  const [productData, setProductData] = React.useState();
    const [drawer, setDrawer] = React.useState<any>({
      placement: 'right',
      title: '',
      size: 'md',
      backdrop: false,
      show: false,
    });

  React.useEffect(() => {
    http.get('http://vlinderserver.online:3014/formsuite?cid=provenanceCreateProduct')
      .then(res => {
        if (res?.data) {
          setData(res?.data?.form?.task_data);
        }
      })
  }, [])

  React.useEffect(() => {
    console.log(`provenance data in useEffect`, productData);
    if (!!productData) {
      _submitFormData();
    }
  }, [productData])

  
    const styles = {
      leftcontainerStyle: {
        display: 'flex',
      },
      rightContainerStyle: {
        float: 'right',
        padding: 10,
      },
      containerStyle: {
        backgroundColor: '#f7f7fa',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'none',
        height: height - (height * 0.16),
        padding: 20,
      },
      mainContainerStyle: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        backgroundColor: '#f7f7fa',
        borderRadius: 'none',
        padding: 20,
      },
      formContainerStyle: {
        width: 500,
        borderWidth: 2,
        borderRadius: 10,
        borderColor: '#d2d3d3',
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 60,
        boxShadow: "1px 3px 1px #d2d3d3",
      }
    };
  
    // const renderDrawerBody = () => {
    //   if (userActionKey === 'adduser') {
    //     return <div />;
    //   }
    //   return <div />;
    // };
  
    // const handleOnDrawerHide = () => {
    //   let _drawer = { ...drawer };
    //   _drawer.show = false;
    //   setDrawer({
    //     ..._drawer,
    //   });
    // };
  
    // const renderDrawerHeader = () => {
    //   return <div />;
    // };
  
    // const renderDrawerFooter = () => {
    //   return <div />;
    // };
  
    const onNavItemClick = (eventKey: string) => {
      setUserActionKey(eventKey);
      console.log(`event key in product`, eventKey)
      // if(eventKey === 'newProduct') {
      //   let _drawer = { ...drawer };
      //   // _drawer.title = humanizeString(eventKey);
      //   _drawer.placement = BP?.drawerPlacement;
      //   _drawer.show = true;
      //   setDrawer({
      //     ..._drawer,
      //   });
      // }
      // if (eventKey === 'newIssuer') {
      //   let _drawer = { ...drawer };
      //   // _drawer.title = humanizeString(eventKey);
      //   _drawer.placement = BP?.drawerPlacement;
      //   _drawer.show = true;
      //   setDrawer({
      //     ..._drawer,
      //   });
      // }
    };
  
    const bodyContent = () => {
      let _prodlist = Array.isArray(props?.products) && props?.products.map(item=>{
        return({
          ...item,
          key:item?.gtin,
          subtitle1: item?.subTitle,
          icon: {
            imageURI: item?.image?.uri ? item?.image?.uri : `https://ui-avatars.com/api/?name=${item.title}`,
            imgWidth: 40,
            imgHeight: 40
          }
        })
      })
        return (
          <CredentialTemplateV
            templateDetails={_prodlist}
            onCardClick={eventKey => props.onCardClick && props.onCardClick(eventKey)}
            containerStyle={StyleSheet.flatten([styles.containerStyle])}
            priceTextColor={theme?.palette?.secondary}
          />
        );      
    }

    // const hederLeftData = () => {
    //   return (
    //     <div style={StyleSheet.flatten([styles.leftcontainerStyle])}>
    //       <Whisper
    //         placement="top"
    //         trigger="hover"
    //         speaker={<Tooltip>Search </Tooltip>}
    //         onClick={props.onSearchClick}
    //       >
    //         <IconButtonV icon={{ name: 'search' }} circle />
    //       </Whisper>
    //     </div>
    //   );
    // }

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  const _submitFormData = () => {
    let formData: object = {};
    let submitedData: any[] = productData ? productData : [];
    console.log(`submitedData:`, submitedData)
    submitedData && Array.isArray(submitedData) && submitedData.forEach((element, index) => {
      // console.log(`on Submin form in add product receivedData`, index)
      if (index === 0) {
        tags = [];
        infolist = [];
        images = [];
      }
      // console.log(`element`, element);
      if (element?.key === 'gtin' && !!element?.value) {
        formData.gtin = element?.value
        formData.details = [
          {
            key: "gtin",
            value: element?.value
          }
        ]
      } else if (element?.key === 'name' && !!element?.value) {
        formData.name = element?.value;
      } else if (element?.key === 'description' && !!element?.value) {
        formData.description = element?.value;
      } else if (element?.key === 'quantity' && !!element?.value) {
        formData.subTitle = element?.value
      } else if (element?.key === 'price' && !!element?.value) {
        formData.price = `Rs${element?.value}`
      } else if (element?.key === 'latitude' && !!element?.value) {
        formData.latitude = parseFloat(element?.value)
      } else if (element?.key === 'longitude' && !!element?.value) {
        formData.longitude = parseFloat(element?.value)
      } else if (element?.key === 'infoTitle1' && !!element?.value) {
        infolist.push({
          key: element?.value
        });
      } else if (element?.key === 'infoDescription1' && !!element?.value) {
        if (infolist.length > 0) {
          infolist[0].description = element?.value
        }
      } else if (element?.key === 'infoTitle2' && !!element?.value) {
        infolist.push({
          key: element?.value
        });
      } else if (element?.key === 'infoDescription2' && !!element?.value) {
        infolist[1].description = element?.value
      } else if (element?.key === 'infoTitle3' && !!element?.value) {
        infolist.push({
          key: element?.value
        });
      } else if (element?.key === 'infoDescription3' && !!element?.value) {
        infolist[2].description = element?.value
      } else if (element?.key === 'infoTitle4' && !!element?.value) {
        infolist.push({
          key: element?.value
        });
      } else if (element?.key === 'infoDescription4' && !!element?.value) {
        infolist[3].description = element?.value
      } else if (element?.key === 'infoTitle5' && !!element?.value) {
        infolist.push({
          key: element?.value
        });
      } else if (element?.key === 'infoDescription5' && !!element?.value) {
        infolist[4].description = element?.value
      } else if (element?.key === 'tagTitle1' && !!element?.value) {
        tags.push({
          key: element?.value
        });
      } else if (element?.key === 'tagImage1' && !!element?.value) {
        tags[0].KeyImageUri = element?.value
      } else if (element?.key === 'tagTitle2' && !!element?.value) {
        tags.push({
          key: element?.value
        });
      } else if (element?.key === 'tagImage2' && !!element?.value) {
        tags[1].KeyImageUri = element?.value
      } else if (element?.key === 'tagTitle3' && !!element?.value) {
        tags.push({
          key: element?.value
        });
      } else if (element?.key === 'tagImage3' && !!element?.value) {
        tags[2].KeyImageUri = element?.value
      } else if (element?.key === 'tagTitle4' && !!element?.value) {
        tags.push({
          key: element?.value
        });
      } else if (element?.key === 'tagImage4' && !!element?.value) {
        tags[3].KeyImageUri = element?.value
      } else if (element?.key === 'tagTitle5' && !!element?.value) {
        tags.push({
          key: element?.value
        });
      } else if (element?.key === 'tagImage5' && !!element?.value) {
        tags[4].KeyImageUri = element?.value
      } else if (element?.key === 'address' && !!element?.value) {
        formData.address = element?.value
      } else if (element?.key === 'productImage1' && !!element?.value) {
        console.log(`productImage 1`, element?.value)
        images.push(`${element?.value}`);
      } else if (element?.key === 'productImage2' && !!element?.value) {
        images.push(element?.value);
      } else if (element?.key === 'productImage3' && !!element?.value) {
        images.push(element?.value);
      }
    });
    console.log(`images :: `, images)
    if (tags && tags.length > 0) {
      formData.tags = tags;
    }
    if (infolist && infolist.length > 0) {
      formData.infolist = infolist;
    }
    if(images && images.length > 0){
      formData.images = images;
    }
    console.log('FormData::', formData)
    // props?.onCreateProductSubmit && props?.onCreateProductSubmit(formData);
    handleOnDrawerHide();
  }

  const handleSubmit = (data: any) => {
    console.log(`data length`, data.length);
    console.log(`on Submit form in add product`, data)
    setTimeout(function () {
      setProductData(data);
    }, 3000)
    
  }

  const renderDrawerBody = () => {
    return (
      <ScrollView>
        <View style={StyleSheet.flatten([styles.mainContainerStyle])}>
          {data &&
            <FormGeneratorV
              // back_action="/"
              // back_name="Back"
              action_name="Submit"
              form_action="/"
              form_method="POST"
              onSubmit={handleSubmit}
              data={data && data}
              //containerStyle={StyleSheet.flatten([styles.formContainerStyle])}
              containerStyle={{ width: 500, borderWidth: 2, borderRadius: 10, borderColor: '#d2d3d3', paddingLeft: 40, paddingRight: 40, paddingBottom: 60, boxShadow: "1px 3px 1px #d2d3d3" }}
              submitButtonContainerStyle={{ borderRadius: 6, backgroundColor: '#F05776', marginTop: 30 }}
              backButtonContainerStyle={{ borderRadius: 6, marginTop: 30 }}
              backButtonTextStyle={{ color: '#0073FF' }}
              submitButtonTextStyle={{ color: '#fff', textAlign: 'center', alignSelf: 'center' }}
            />
          }
        </View>
      </ScrollView>
    );
  }

    return (
      <>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
        <BodyTemplateV
          primaryColor={BP?.primaryColor || theme?.palette.primary}
          primaryHighlightColor={
            BP?.primaryHighlightColor || theme?.palette.secondary
          }
          secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
          ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
          renderContent={bodyContent}
          dashboardContainerStyle={StyleSheet.flatten([styles.containerStyle])}
          contentScrollableInline={true}
          headerTitleStyle={{ marginLeft: 20 }}
          navbarStyle={{}}
          layout={'sidebar-header-content-footer'}
          navBarRightItems={BP?.navRightItems}
        //   navBarLeftItems={BP?.navLeftItems}
          onNavItemSelect={onNavItemClick}
          headerBackgroundColor={'#fff'}
          renderFooter={() => <div />}
        />
        <DrawerV
          placement={drawer.placement}
          show={drawer.show}
          backdrop={drawer.backdrop}
          size={drawer.size}
          renderHeader={renderDrawerHeader}
          onHide={handleOnDrawerHide}
          drawerTitle={drawer.title}
          renderBody={renderDrawerBody}
          renderFooter={renderDrawerFooter}
        />
      </>
    );
}
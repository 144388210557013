import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import config from '../../config/default-blueprint.json';
import { ThemeContext } from '../../../../providers/theme';
import { useViewport } from 'react-viewport-provider';
import { DashboardContainerV } from 'react-dashboard-container-rs';
import { DrawerV } from 'react-drawer-rs';
import { OrganizationProfile } from './components/OrganizationProfile';
import { OperatorProfile } from './components/OperatorProfile';
import { ProfileTemplate } from './components/ProfileTemplate';
import { IntroCard } from './components/IntroCard';
import { CredentailCategory } from './components/credentials/CredentialCategory';
import { CredentailList } from './components/credentials/components/credentialList';
import { IssueCredential } from './components/credentials/components/issueCredential';
import { Admins } from './components/Admins';
import { Verification } from './components/VerificationPage';
import { VerificationRequest } from './components/VerificationPage/components/VerificationSuite';
import { DashboardGraph } from './components/dashboard/Dashboard';
import { Feedback } from './components/feedback';
import { HolderGroups } from './components/holderGroups';
import { TrustNetwork } from './components/networks';
import { IssuersList } from './components/Issuers';
import { VerifiersList } from './components/Verifiers';
import { HoldersList } from './components/holders';
import { UserList } from './components/holderGroups/userlist/UserList';
import { Noterize } from './components/notarize';
import { CredentialView } from './components/networks/components/credential-view';
import { ProductView } from './components/batches';
import { BatchView } from './components/batches/components/BatchesList';
import { Transactions } from './components/transactions';
import { Enrolment } from './components/enrolment';
import { IProps } from './index';
import { CounterfeitAlert } from './components/counterfeitAlerts';
import { AddProvenance } from './components/addProvenance';
import Icon from 'rsuite/lib/Icon';
import { AvatarV } from 'react-native-avatar-vfw';
import {Charts} from './components/charts';

const humanizeString = require('humanize-string');
const BP = config.dashboard;



export const DashboardLG = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const [drawer, setDrawer] = React.useState<any>({
    placement: 'right',
    title: '',
    size: 'xs',
    backdrop: false,
    show: false,
  });
  const [navBarEventKey, setNavBarEventKey] = React.useState<string>();
  const [sideBarEventKey, setSideBarEventKey] = React.useState<string>();
  const [formData, setFormData] = useState<any>();
  const [drawerWidth, setDrawerWidth] = useState(330);
  const { width, height } = useViewport();
  const [claims, setClaims] = useState<any>([]);

  const styles = StyleSheet.create({
    sidebarHeaderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: StyleSheet.hairlineWidth,
      height: 0.06 * height,
    },
    sidebarHeaderTitleStyle: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: 22,
    },
    chartStyles: {
      margin: 5
    }
  });

  const RenderSidenavHeader = () => {
    return (
      <div
        style={{

          padding: 18,
          fontSize: 16,
          height: 56,
          background: '#5987af',
          color: ' #fff',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }}
      >
        <Icon icon="logo-analytics" size="lg" style={{ verticalAlign: 0 }} />
        <span style={{ marginLeft: 18 }}>{BP.sidebarHeaderTitle}</span>
      </div >
    );
  };

  const graphData: any = [

    {
      type: 'cardData',
      styles: {
        marginTop: '20px',

      },
      data: [
        {
          backgroundColor: 'linear-gradient(to top left , #42aa, #bd35f3)',
          icon: 'user',
          title: 'Transactisons',
          total: '214',
          description: '',
        },
        {
          backgroundColor: 'linear-gradient(to bottom, #cdeb8e 0%,#a5c956 80%)',
          icon: 'qrcode',
          title: 'Scans',
          total: '214',
          description: '',
        },
        {
          backgroundColor: 'linear-gradient(to top, #654ea3, #eaafc8)',
          icon: 'tags',
          title: 'Taps',
          total: '214',
          description: '',
        },
        {
          backgroundColor: 'linear-gradient(to bottom,#4ecdc4 , #556270 )',
          icon: 'archive',
          title: 'Products',
          total: '214',
          description: '',
        },
        {
          backgroundColor: 'linear-gradient(to bottom,  #12ebe2,#2990ee )',
          icon: 'th-large',
          title: 'Batches',
          total: '214',
          description: '',
        },
        {
          backgroundColor: 'linear-gradient(to bottom, #ffb88c,#de6262)',
          icon: 'star',
          title: 'Feedbacks',
          total: '214',
          description: '',
        },
      ],
    },
    {
      type: 'donutchart',

      mediumDevice: 6,
      smallDevice: 12,
      styles: {
        margin: '30px',
        padding: '15px',
        marginTop: '20px',

        borderRadius: '5px',
      },
      data: {
        id: "2",
        height: 150,
        title: 'FeedBack',
        labels: ['Poor', 'Average', 'Good', 'Very Good', 'Excellent'],
        datasets: [
          {
            data: [300, 50, 100, 40, 120],
            backgroundColor: [
              '#F7464A',
              '#46BFBD',
              '#FDB45C',
              '#949FB1',
              '#4D5360',
            ],
            hoverBackgroundColor: [
              '#FF5A5E',
              '#5AD3D1',
              '#FFC870',
              '#A8B3C5',
              '#616774',
            ],
          },

        ],
      },
    },

    //Bar graph - transaction vs products
    {
      type: 'barchart-transaction',
      mediumDevice: 6,
      smallDevice: 12,
      styles: {
        margin: '30px',
        padding: '15px',
        marginTop: '20px',

        borderRadius: '5px',
      },
      data: {
        id: '1',
        height: 150,
        title: 'Transaction - Products',
        labels: ['Amrut Fusion X-1', 'Amrut Silver MaQintosh', 'Amrut Fusion X-2', 'Amrut Fusion X-3'],
        datasets: [
          {
            label: '% of Transactions',
            data: [12, 19, 3, 5],
            backgroundColor: [
              'rgba(255, 134,159,0.4)',
              'rgba(98,  182, 239,0.4)',
              'rgba(255, 218, 128,0.4)',
              'rgba(113, 205, 205,0.4)',
              'rgba(170, 128, 252,0.4)',
              'rgba(255, 177, 101,0.4)',
            ],
            borderWidth: 2,
            borderColor: [
              'rgba(255, 134, 159, 1)',
              'rgba(98,  182, 239, 1)',
              'rgba(255, 218, 128, 1)',
              'rgba(113, 205, 205, 1)',
              'rgba(170, 128, 252, 1)',
              'rgba(255, 177, 101, 1)',
            ],
          },
        ],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    },
    //Bar graph - country vs transaction


    {
      type: 'barchart-country',
      mediumDevice: 6,
      smallDevice: 12,
      styles: {
        margin: '30px',
        padding: '15px',
        marginTop: '20px',

        borderRadius: '5px',
      },
      data: {
        id: '1',
        height: 150,
        title: 'Transaction-Country ',
        labels: ['India', 'United States', 'United Kingdom', 'Rest of the World'],
        datasets: [
          {
            label: '% of Transactions',
            data: [12, 19, 3, 5],
            backgroundColor: [
              'rgba(255, 134,159,0.4)',
              'rgba(98,  182, 239,0.4)',
              'rgba(255, 218, 128,0.4)',
              'rgba(113, 205, 205,0.4)',
              'rgba(170, 128, 252,0.4)',
              'rgba(255, 177, 101,0.4)',
            ],
            borderWidth: 2,
            borderColor: [
              'rgba(255, 134, 159, 1)',
              'rgba(98,  182, 239, 1)',
              'rgba(255, 218, 128, 1)',
              'rgba(113, 205, 205, 1)',
              'rgba(170, 128, 252, 1)',
              'rgba(255, 177, 101, 1)',
            ],
          },
        ],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    },

    //Bar graph - Months vs transaction


    {
      type: 'barchart-months',
      mediumDevice: 6,
      smallDevice: 12,
      styles: {
        margin: '30px',
        padding: '15px',
        marginTop: '20px',

        borderRadius: '5px',
      },
      data: {
        id: '1',
        height: 150,
        title: 'Transaction - Month',
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        datasets: [
          {
            label: '% of Transactions',
            data: [12, 19, 3, 5, 9, 8, 6, 4, 7, 9, 10, 25],
            backgroundColor: [
              'rgba(255, 134,159,0.4)',
              'rgba(98,  182, 239,0.4)',
              'rgba(255, 218, 128,0.4)',
              'rgba(113, 205, 205,0.4)',
              'rgba(170, 128, 252,0.4)',
              'rgba(255, 177, 101,0.4)',
              'rgba(255, 154,162,0.4)',
              'rgba(98,  208, 211,0.4)',
              'rgba(255, 230, 148,0.4)',
              'rgba(113, 198, 195,0.4)',
              'rgba(170, 171, 152,0.4)',
              'rgba(255, 168, 99,0.4)',
            ],
            borderWidth: 2,
            borderColor: [
              'rgba(255, 134,159,1)',
              'rgba(98,  182, 239,1)',
              'rgba(255, 218, 128,1)',
              'rgba(113, 205, 205,1)',
              'rgba(170, 128, 252,1)',
              'rgba(255, 177, 101,1)',
              'rgba(255, 154,162,1)',
              'rgba(98,  208, 211,1)',
              'rgba(255, 230, 148,1)',
              'rgba(113, 198, 195,1)',
              'rgba(170, 171, 152,1)',
              'rgba(255, 168, 99,1)',
            ],
          },
        ],
      },
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)',
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    },
  ];

const renderContent = () => {
    if (sideBarEventKey === 'analytics') {
      return <DashboardGraph drawerWidth={drawerWidth} />;
    }
    else if(sideBarEventKey === 'dashboard'){
      return (
        <View style={StyleSheet.flatten([styles.chartStyles])}>
          <Charts graphData={graphData}  />
        </View>
      )
    }
    else if (sideBarEventKey === 'feedback') {
      return <Feedback
        feedbacks={props?.feedbacks}
        feedbackPageChange={props?.feedbackPageChange}
        feedbackLoading={props?.feedbackLoading}
        feedbackStats={props?.feedbackStats}
        productsAndBatches={props?.productsAndBatches}
        onRatingFilter={props?.onRatingFilter}
        onFeedbackFilterApply={props?.onFeedbackFilterApply}
      />;
    }
    else if (sideBarEventKey === 'products') {
      return <ProductView
        onCardClick={eventKey => {
          setSideBarEventKey('batchView');
          props?.onProductSelect && props?.onProductSelect(eventKey);
        }}
        products={props?.products}
        onCreateProductSubmit={props?.onCreateProductSubmit}
      />;
    }
    else if (sideBarEventKey === 'batchView') {
      return <BatchView
        batches={props?.batches}
        onCardClick={(eventKey: string, title: string) => {
          props?.onBatchSelect && props?.onBatchSelect(eventKey, title);
        }}
        breadCrumbsClick={key => setSideBarEventKey(key)}
        onUpdateBatch={props?.onUpdateBatch}
        updateBatchSuccess={props?.updateBatchSuccess}
        activeGtin={props?.activeGtin}
        getQRData={props?.getQRData}
        getQRDataURL={props?.getQRDataURL}
      />
    }
    else if (sideBarEventKey === 'transactions') {
      return <Transactions
        transactions={props?.transactions}
        transactionStats={props?.transactionStats}
        tranactionsLoading={props?.tranactionsLoading}
        transactionsPageChange={props?.transactionsPageChange}
        productsAndBatches={props?.productsAndBatches}
        onFilterApply={props?.onFilterApply}
        scanCities={props?.scanCities}
        scanCountries={props?.scanCountries}
        scanRegions={props?.scanRegions}
        completeTrasactions={props?.completeTrasactions}
        getFilteredTransactions={props?.getFilteredTransactions}
      />
    }
    else if (sideBarEventKey === 'enrolment') {
      return <Enrolment
        enrolments={props?.enrolments}
        enrolmentStats={props?.enrolmentStats}
        enrolmentLoading={props?.feedbackLoading}
        enrolmentsPageChange={props?.enrolmentsPageChange}
        productsAndBatches={props?.productsAndBatches}
        onEnrolmentFilterApply={props?.onEnrolmentFilterApply}
        scanCities={props?.scanCities}
        scanCountries={props?.scanCountries}
        scanRegions={props?.scanRegions}
        completeEnrolments={props?.completeEnrolments}
        getFilteredEnrolments={props?.getFilteredEnrolments}
      />
    }
    else if (sideBarEventKey === 'counterfeit') {
      return <CounterfeitAlert
        counterfeitData={props?.counterfeitData}
        counterfeitStats={props?.counterfeitStats}
        counterfeitLoading={props?.counterfeitLoading}
        counterfeitPageChange={props?.counterfeitPageChange}
        counterfeitFilterApply={props?.counterfeitFilterApply}
        productsAndBatches={props?.productsAndBatches}
        scanCities={props?.scanCities}
        scanCountries={props?.scanCountries}
        scanRegions={props?.scanRegions}
      />
    }
    else if (sideBarEventKey === 'provenance') {
      return (
        <AddProvenance 
          onCreateProvenanceSubmit={props?.onCreateProvenanceSubmit}
        />
      );
    }
    return <OrganizationProfile
      orgProfileData={props?.orgProfileData}
    />
    // return(
    //   <div style={{justifyContent:'center', alignItems:'center'}}>
    //     <h4 style={{textAlign:'center', alignItems:'center', position: 'absolute', top: '50%', left: '50%'}}>No Data Available</h4>
    //   </div>
    // )

  };

  const renderFooter = () => {
    return <div />;
  };

  const handleOnNavItemSelect = (eventKey: string) => {
    setNavBarEventKey(eventKey);
    if (eventKey === 'notifications') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP.notificationsDrawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
    if (eventKey === 'profile') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP.notificationsDrawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
  };

  const handleOnSideNavItemselect = (eventKey: string) => {
    setSideBarEventKey(eventKey);
    props.sidebarNavItemSelect && props.sidebarNavItemSelect(eventKey);
  };

  const setExpanded = (eventKey: boolean) => {
    if (eventKey) {
      setDrawerWidth(330);
    } else {
      setDrawerWidth(120);
    }
  };

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const renderDrawerBody = () => {
    if (navBarEventKey === 'profile') {
      return <ProfileTemplate
        userProfile={props?.userProfile}
      />;
    }
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  let _navBarItems = Array.isArray(BP?.navBarItems) && BP?.navBarItems.map(item => {
    if (item?.eventKey === 'profile') {
      return ({
        ...item,
        avatar: {
          src: props?.userProfile?.logo,
          title: props?.userProfile?.fullName.match(/\b(\w)/g)
        }
      });
    }
    else {
      return ({
        ...item
      });
    }
  })

  return (
    <>
      <DashboardContainerV
        navBarRightItems={_navBarItems}
        headerLogo={BP.headerLogo}
        headerTitle={BP.headerTitle}
        primaryColor={theme?.palette.primary}
        secondaryColor={theme?.palette.secondary}
        ternaryColor={theme?.palette.ternary}
        primaryHighlightColor={theme?.palette.primaryDark}
        sidebarWidth={180}
        sideBarItems={BP?.sidebarItems}
        renderContent={renderContent}
        headerTitleStyle={{}}
        drawerCloseIconColor={'#fff'}
        navbarStyle={{}}
        onNavItemSelect={handleOnNavItemSelect}
        onSideNavItemSelect={handleOnSideNavItemselect}
        renderSidenavHeader={RenderSidenavHeader}
        renderFooter={renderFooter}
        isexpanded={eventKey => setExpanded(eventKey)}
      />
      <DrawerV
        placement={drawer.placement}
        show={drawer.show}
        backdrop={drawer.backdrop}
        size={drawer.size}
        renderHeader={renderDrawerHeader}
        onHide={handleOnDrawerHide}
        drawerTitle={drawer.title}
        renderBody={renderDrawerBody}
        renderFooter={renderDrawerFooter}
      />
    </>
  );
};

import {MDBContainer} from "mdbreact";
import React from "react";
import {Bar} from "react-chartjs-2";


export type BarChartProps = {
    BarChartData?:any;
    barChartOptions?:any;
}

const BarChart=(props:BarChartProps)=> {
  return (
    <MDBContainer>

<h3>{props?.BarChartData?.title}</h3>
      <Bar data={props?.BarChartData} options={props?.barChartOptions} height={props?.BarChartData?.height} />

    </MDBContainer>
  );
}

export default BarChart;










import React from 'react';
import {
    FlatList,
    StyleSheet,
    TextStyle,
    TouchableOpacity,
    View,
    ViewStyle,
    ScrollView
} from 'react-native';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {titleCase} from 'title-case';
import {TextV} from 'react-native-text-vfw';
import {ListItemV} from 'react-native-list-item-vfw';
import {useViewport} from 'react-viewport-provider';
import {IconV} from 'react-native-icon-vfw';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import {ThemeContext} from '../../../providers/theme';
import {useProductView} from '../../ProductView/provider';



interface FloatingProductInfoProps {
    handleBackPress?(): void;
    viewport?: string;
};

export const FloatingProductInfo = ({
  handleBackPress,
  viewport
       }: FloatingProductInfoProps) => {
         const {fabProductInfo} = useProductView();
         const theme = React.useContext(ThemeContext);
         const {height} = useViewport();
         const history = useHistory();
         const location = useLocation();
         // const [show, setShow] = React.useState<boolean>(props.show);

         // const handleOnHide = () => {
         //     show && setShow(false)
         // }

         const styles = StyleSheet.create({
           container: {
             flexGrow: 1,
             margin: 20,
             height: viewport ? 570 : height
           },
           hzLineStyle: {
             height: 2,
             alignSelf: 'center',
           },

           headerTitleStyle: {
             textAlign: 'center',
             alignSelf: 'center',
             letterSpacing: 1,
             paddingVertical: 10,
           },
           listContainer: {
             paddingTop: 15,
           },
           detailsContainer: {
             flexGrow: 1,
             paddingBottom: 30,
           },
           detailsHeaderTitleStyle: {
             textAlign: 'center',
             alignSelf: 'center',
             letterSpacing: 1,
             paddingVertical: 10,
           },
           detailslistContainer: {
             padding: 10,
           },
           detailshzLineStyle: {
             height: 2,
             alignSelf: 'center',
           },
           prodInstructionStyle: {
             fontSize: 14,
             textAlign: 'left',
             padding: 20,
           },
           subTitleStyle: {
             fontSize: 12,
           },
           rightTitleStyle: {
             fontSize: 13,
           },
         });

         const _renderItems = (item: any, index: number) => {
           return (
             <ListItemV
               key={index}
               //@ts-ignore
               title={
                 item && typeof item.name === 'string' && titleCase(item.name)
               }
               //@ts-ignore
               subtitle={
                 item &&
                 typeof item.subInfo === 'string' &&
                 titleCase(item.subInfo)
               }
               //@ts-ignore
               subtitleStyle={StyleSheet.flatten([
                 styles.subTitleStyle,
                 {color: '#ACACAC'},
               ])}
               //@ts-ignore
               rightTitle={
                 item &&
                 typeof item.quantity === 'string' &&
                 titleCase(item.quantity)
               }
               rightTitleStyle={StyleSheet.flatten([styles.rightTitleStyle])}
             />
           );
         };

         const _renderDetails = (productDetails: any) => {
           let details = [];
           productDetails.map((item, index) => {
             details.push(_renderItems(item, index));
           });
           return details;
         };

         const _getDetails = (item: any) => {
           return (
             <ScrollView
               //@ts-ignore
               contentContainerStyle={StyleSheet.flatten([
                 styles.detailsContainer,
                 {backgroundColor: '#fff'},
               ])}
               showsHorizontalScrollIndicator={false}
               showsVerticalScrollIndicator={false}
             >
               {item &&
                 item.content &&
                 Array.isArray(item.content) &&
                 _renderDetails(item.content)}
               {item && item.description && (
                 <TextV
                   type={'title3'}
                   children={
                     typeof item.title === 'string' &&
                     titleCase(item.description)
                   }
                   style={StyleSheet.flatten([
                     styles.prodInstructionStyle,
                     {
                       color: '#373a3b',
                     },
                   ])}
                 />
               )}
               {/* </View> */}
             </ScrollView>
           );
         };

         return (
           <View
             //@ts-ignore
             style={StyleSheet.flatten([
               styles.container,
               {backgroundColor: '#fff'},
             ])}
           >
             <ScrollView>
             {!location?.state?.backButton ? null : (
               <TouchableOpacity hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
               <IconV
                 name={'closecircle'}
                 type={'antdesign'}
                 onPress={handleBackPress}
                 size={26}
                 color={'#000'}
                 containerStyle={{
                   alignSelf: 'flex-end',
                   marginTop: 20,
                   marginRight: 20,
                   marginBottom: 10,
                 }}
               />
               </TouchableOpacity>
             )}
             {viewport && viewport ? 
                 <TouchableOpacity hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
                   <IconV
                     name={'closecircle'}
                     type={'antdesign'}
                     onPress={handleBackPress}
                     size={26}
                     color={'#000'}
                     containerStyle={{
                       alignSelf: 'flex-end',
                       marginTop: 20,
                       marginRight: 20,
                       marginBottom: 10,
                     }}
                   />
                 </TouchableOpacity>
                 : null  
            }
             {fabProductInfo?.headerTitle && (
               <>
                 <TextV
                   type={'title3'}
                   children={
                     typeof fabProductInfo?.headerTitle === 'string' && titleCase(fabProductInfo?.headerTitle)
                   }
                   ellipsizeMode={'tail'}
                   numberOfLines={1}
                   style={StyleSheet.flatten([
                     styles.headerTitleStyle,
                     {
                       color: '#373a3b',
                     },
                   ])}
                 />
                 <View
                   //@ts-ignore
                   style={StyleSheet.flatten([
                     styles.hzLineStyle,
                     { width: fabProductInfo?.headerTitle.length > 15 ? 100 : 50},
                     {backgroundColor: theme?.palette?.secondary},
                   ])}
                 />
               </>
             )}
             {fabProductInfo?.productInfoList && Array.isArray(fabProductInfo?.productInfoList) && (
               // <FlatList
               //     data={productInfoList} //@ts-ignore
               //     listKey={(item: any, index: any) => {
               //         return `${index}-${new Date()}`;
               //     }}
               //     renderItem={_renderItems}
               //     contentContainerStyle={StyleSheet.flatten([
               //         styles.listContainer,
               //     ])}
               //     showsVerticalScrollIndicator={false}
               // />
               <View style={{paddingTop: 30}}>
                 <Accordion allowZeroExpanded>
                   {fabProductInfo?.productInfoList.map(item => (
                     <AccordionItem>
                       <AccordionItemHeading>
                         <AccordionItemButton>
                           {item &&
                             typeof item.title === 'string' &&
                             titleCase(item.title)}
                         </AccordionItemButton>
                       </AccordionItemHeading>
                       <AccordionItemPanel>
                         {_getDetails(item)}
                       </AccordionItemPanel>
                     </AccordionItem>
                   ))}
                 </Accordion>
               </View>
             )}
             </ScrollView>
           </View>
         );

         // const styles = {
         //     containerStyle: {
         //         margin: 20,
         //         height: height * 1
         //     },
         //     titleStyle: {
         //         fontWeight: 'bold',
         //         fontSize: 18,
         //         paddingTop: 10,
         //     }
         // };

         // const _getData = (details: any) => {
         //     let data = [];
         //     details && details.length > 0 &&
         //     details.forEach((info: any, index: number) => {
         //         data.push(
         //             <ListItemV
         //                 key={index}
         //                 bottomDivider
         //                 leftIcon={{
         //                     name: 'dot-single',
         //                     type: 'entypo'
         //                 }}
         //                 title={info}
         //                 containerStyle={{
         //                     marginLeft: 10
         //                 }}
         //             />
         //         );
         //     });
         //     if (details && details.length > 0) {
         //         return data;
         //     } else {
         //         return (
         //             <TextV
         //                 type={'body'}
         //                 children={
         //                     titleCase('none')
         //                 }
         //                 style={StyleSheet.flatten([{
         //                     marginLeft: 10
         //                 }])}
         //                 ellipsizeMode={'tail'}
         //                 numberOfLines={1}
         //             />
         //         );
         //     }
         // }

         // const _getInfo = () => {
         //     let info = [];
         //     const data = BP.info;
         //     data.forEach((item , index) => {
         //         info.push(
         //             <>
         //             <TextV
         //                 type={'title3'}
         //                 children={
         //                     typeof item.title === 'string' && titleCase(item.title)
         //                 }
         //                 style={StyleSheet.flatten([styles.titleStyle])}
         //                 ellipsizeMode={'tail'}
         //                 numberOfLines={1}
         //             />
         //             {_getData(item.detail)}
         //             </>
         //         );
         //     });
         //     return (
         //       <View
         //         style={{
         //             marginTop: 10,
         //             padding: 10,
         //             height: height * 1,
         //             boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
         //         }}
         //       >
         //         {info}
         //       </View>
         //     );
         // }

         // return (
         //     <View style={StyleSheet.flatten([styles.containerStyle])}>
         //         <IconV
         //             name={'closecircle'}
         //             type={'antdesign'}
         //             onPress={handleBackPress}
         //             size={20}
         //             color={'#000'}
         //             containerStyle={{
         //                 alignSelf: 'flex-end',
         //                 paddingHorizontal: 5,
         //                 marginBottom: 10
         //             }}
         //         />
         //         {_getInfo()}
         //     </View>
         // );
       };
import React, { useContext, useLayoutEffect, useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import { EventBus } from 'event-bus-vfs';
import {useViewport} from 'react-viewport-provider';
import config from '../../config/default-blueprint.json';
import { ThemeContext } from '../../../../../../../../providers/theme';
import { BodyTemplateV } from 'react-body-template-1';
import { CredentialTemplateV } from 'react-credentail-template';
import { DrawerV } from 'react-drawer-rs';
import { QRCodeGeneratorV } from 'react-native-qr-code-generator-vfw';
import Modal from 'rsuite/lib/Modal'; 
import Button from 'rsuite/lib/Button';
import Toggle from 'rsuite/lib/Toggle';
import Nav from 'rsuite/lib/Nav';
import Input from 'rsuite/lib/Input';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { Document } from 'react-pdf';
import {ButtonV, IconButtonV } from 'react-button-rs';
import {FormV, FORM_VALUES, defaultValuesDS } from 'react-form-rs';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import Alert from 'rsuite/lib/Alert';
import { FormGeneratorV, HttpServer } from '../../../../../../../../widgets/FormBuildGenV/src';
import {servers} from '../../../../../../../../config/default.config.json'

interface IProps {
    onCardClick?(eventKey?: any, title?: string): void;
    batches?:any[];
    breadCrumbsClick?(key?:string):void;
    onUpdateBatch?(key?:string, formData?:any):void;
    updateBatchSuccess?:boolean;
    activeGtin?:string;
    getQRData?:string;
    getQRDataURL?: string;
};
const http = new HttpServer();

const BP = config?.batchProducts;
let formContext = {};
export const BatchView = (props: IProps) => {
    const {httpHost, provenanceEP} = servers?.http;
    const theme = useContext(ThemeContext);
    const { width, height } = useViewport();
    // const [data, setData] = React.useState<any>([]);
    const [validateForm, setValidateForm] = useState<any>(null);
    const [userActionKey, setUserActionKey] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [modalKey, setModalKey] = React.useState<string>();
    const [toggle, setToggle] = useState<boolean>(false);
    const [tabActive, setTagActive] = useState<string>('batchInfo');
    const [promoVideoCopied, setPromoVideoCopied] = useState<boolean>(false);
    const [batchDetails, setBatchDetails] = useState<any[]>();
    const [activeBatchKey, setActiveBatchKey] = useState<string>();
    const [data, setData] = React.useState<any>();
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'md',
        backdrop: false,
        show: false,
    });

    React.useEffect(() => {
        http.get('http://vlinderserver.online:3014/formsuite?cid=provenanceCreateBatch')
            .then(res => {
                if (res?.data) {
                    setData(res?.data?.form?.task_data);
                }
            })
    }, [])

    const onModalClose = () => {
        if (modalKey === 'editbatch') {
            defaultValuesDS.clear()
            setModalKey('modalQR')
        } else if (show) {
            setShow(false);
        }
    }

    if (promoVideoCopied) {
        Alert.success('Copied!');
        setPromoVideoCopied(false);
    }

    useEffect(() => {
        defaultValuesDS.clear()
        const app = EventBus.on(FORM_VALUES, value => {
            formContext = { ...value };
        });
        return function () {
            app.off();
        };
    }, []);

    useEffect(()=>{
        if(props?.updateBatchSuccess){
            setShow(false);
            setModalKey('');
            defaultValuesDS.clear();
        }
    },[props?.updateBatchSuccess]);
  
    const styles = {
      leftcontainerStyle: {
        display: 'flex',
      },
      rightContainerStyle: {
        float: 'right',
        padding: 10,
      },
      modalStyle: {
        width: '70%',
        alignSelf: 'center',
        alignItems: 'center',
        margin: '4% auto 0 auto'
    },
      containerStyle: {
        backgroundColor: '#f7f7fa',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'none',
        height: height - (height * 0.16),
        padding: 20,
      },
      outerQrStyle: {
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center'
    },
    qrStyle: {
        backgroundColor: '#dcdcdc',
        padding: 8,
        width: 266,
        margin: '0 auto',
        display:'block',
    },
    textStyle: {
        textAlign: 'center',
        fontSize: 24,
        fontWeight: 500,
        alignSelf: 'center',
    },
    buttonGroupStyle: { position: 'fixed', alignItems: 'flex-end', padding: 2, bottom: 0, right: 0 },
    panelStyle: {
        backgroundColor: '#fff',
        width: 350,
        // minHeight: 200,
        margin: 20,
        borderRadius: 10,
        shadowOpacity: 0.4,
        shadowRadius: 5,
        shadowColor: '#373a3b',
        elevation: 2,
        shadowOffset: { height: 0, width: 0 },
    },
    iconContainerStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        alignSelf: 'center'
    },
    dateContainerStyle: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItem: 'center',
        borderTop: '1px dotted #ccc'
    },
    avatarContainerStyle: {
        backgroundColor: '#fff',
        //  borderRadius: 10,
        shadowOpacity: 0.4,
        shadowRadius: 5,
        shadowColor: '#373a3b',
        elevation: 4,
        shadowOffset: { height: 0, width: 0 },
    },
    headingStyle: {
        fontSize: 20,
        alignSelf: 'center',
        fontWeight: 600,
        paddingLeft: 15
    },
    dateHeadingStyle: {
        display: 'block',
        fontSize: 14,
        fontWeight: '500',

    },
    dateStyle: {
        fontSize: 12,
    },
    issuerTitleStyle: {
        display: 'block',
        fontSize: 16,
        fontWeight: 500,
        paddingTop: 8
    },
    gridStyle: {
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignSelf: 'center',
        backgroundColor: '#ececec',
        borderBottom: '2px solid #f1f1f1',
    },
    headerGridStyle: {
        width: '70%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: '#3498FF',
        borderBottom: '2px solid #f1f1f1',
    },
    labelStyle: {
        padding: 10,
        fontSize: 14,
        fontWeight: 500
    },
    valueStyle: {
        padding: 10,
        fontSize: 14,
    },
    tableHeadingStyle: {
        fontSize: 28,
        fontWeight: 500,
        color: '#fff',
        margin: '0 auto',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 5
    },
    linkContainerStyle: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width:'80%',
        alignSelf: 'center'
    },
    batchInfoContainer:{
        padding:12,
    },
        mainContainerStyle: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
            backgroundColor: '#f7f7fa',
            borderRadius: 'none',
            padding: 20,
        },
        formContainerStyle: {
            width: 500,
            borderWidth: 2,
            borderRadius: 10,
            borderColor: '#d2d3d3',
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 60,
            boxShadow: "1px 3px 1px #d2d3d3",
        }
    };
  
    // const renderDrawerBody = () => {
    //   if (userActionKey === 'adduser') {
    //     return <div />;
    //   }
    //   return <div />;
    // };
  
    // const handleOnDrawerHide = () => {
    //   let _drawer = { ...drawer };
    //   _drawer.show = false;
    //   setDrawer({
    //     ..._drawer,
    //   });
    // };
  
    // const renderDrawerHeader = () => {
    //   return <div />;
    // };
  
    // const renderDrawerFooter = () => {
    //   return <div />;
    // };
  
    const onNavItemClick = (eventKey: string) => {
    //   setUserActionKey(eventKey);
    //   setModalKey('modalForm');
    //     if (eventKey === 'newBatch') {
    //         let _drawer = { ...drawer };
    //         // _drawer.title = humanizeString(eventKey);
    //         _drawer.placement = BP?.drawerPlacement;
    //         _drawer.show = true;
    //         setDrawer({
    //             ..._drawer,
    //         });
    //     }
      //for add new batch
    //   if(!show){
    //     setShow(true);
    //   }
      
    };

    const onCardClick = (eventKey: string, title: string) => {
        props?.onCardClick && props?.onCardClick(activeBatchKey, title);
        console.log(`props?.batches`, props?.batches)
        let _batchDetails = Array.isArray(props?.batches) && props?.batches.filter(item=>{
            if(item?.key === eventKey){
                return true
            }
        });
        let _details;
        let _key;
        if(_batchDetails[0]){
         _details = _batchDetails[0]?.details;
         _key = _batchDetails[0]?.key;
        }
        setActiveBatchKey(_key);
        setBatchDetails(_details);
        setModalKey('modalQR');
        setShow(true);
    }
   const onNextButtonClick = () => {
    setValidateForm({
        ...formContext,
      })
    setModalKey('modalQR');
    }
    const onToggleChange=(checked:boolean) => {
        if (checked) {
            setToggle(true)
        }
        else {
            setToggle(false)
        }  
    }
    const onSelect = (activeKey:string) => {
        setTagActive(activeKey);
    }

    const onQrDownload = () => {
        window.open('http://africau.edu/images/default/sample.pdf', '_blank');
    }

    const _getBatchInfo = () => {
        const _batchInfo:any=[];
        Array.isArray(batchDetails) && batchDetails.forEach(item=>{
            if(item.key === 'promoVideoUrl'){
                _batchInfo.push(
                    <FBItemV colspan={11} style={StyleSheet.flatten([styles.batchInfoContainer])}>
                        <Text style={{display:'block', fontSize:16, fontWeight:400}}>{item?.label}</Text>
                        <Input placeholder={item?.value} disabled/> 
                        <CopyToClipboard text={item?.value}
                        onCopy={()=>{setPromoVideoCopied(true)}}
                        >
                        <IconButtonV
                            icon={{
                                name: 'copy',
                              }}
                              color={'#ccc'}
                              circle
                        />
                        </CopyToClipboard>
                        {/* {promoVideoCopied ? <Text style={{paddingLeft:10, color:'green', fontSize:14}}>{`${item?.label} Copied!`}</Text>: null} */}
                    </FBItemV>
                ) 
            }
            else if(item?.key === 'isPromoEnabled'){
                _batchInfo.push(
                    <FBItemV colspan={11} style={StyleSheet.flatten([styles.batchInfoContainer])}>
                        <Text style={{display:'block', fontSize:16,  fontWeight:400}}>{item?.label}</Text>
                        <Text style={{display:'block'}}>{(item?.value==='true')? "Enabled": "Disabled"}</Text>
                    </FBItemV>
                )  
            }
            else if (item?.key === 'contestButtonText') {
                _batchInfo.push(
                    <FBItemV colspan={11} style={StyleSheet.flatten([styles.batchInfoContainer])}>
                        <Text style={{ display: 'block', fontSize: 16, fontWeight: 400 }}>{'Button Text 1'}</Text>
                        <Text style={{ display: 'block' }}>{item?.value}</Text>
                    </FBItemV>
                )
            }
            else if (item?.key === 'promoButtonText') {
                _batchInfo.push(
                    <FBItemV colspan={11} style={StyleSheet.flatten([styles.batchInfoContainer])}>
                        <Text style={{ display: 'block', fontSize: 16, fontWeight: 400 }}>{'Button Text 2'}</Text>
                        <Text style={{ display: 'block' }}>{item?.value}</Text>
                    </FBItemV>
                )
            }
            else {
                _batchInfo.push(
                    <FBItemV colspan={11} style={StyleSheet.flatten([styles.batchInfoContainer])}>
                        <Text style={{display:'block', fontSize:16,  fontWeight:400}}>{item?.label}</Text>
                        <Text style={{display:'block'}}>{item?.value}</Text>
                    </FBItemV>
                )
            }
            
        });
        _batchInfo.push(
            <FBItemV colspan={11} style={StyleSheet.flatten([styles.batchInfoContainer])}>
            <Text style={{display:'block', fontSize:16,  fontWeight:400}}>{BP?.provenanceClickTitle}</Text>
            <Text style={{display:'block'}}><a target='_blank' href={props?.getQRDataURL && props?.getQRDataURL}> Click here</a></Text>
        </FBItemV>
        );
        return _batchInfo;
    }

    const _getModalHeader = () => {
        switch(modalKey){
            case 'modalForm': 
                return <h4 style={{textAlign:'center'}}>{BP?.formTitle}</h4>;
            case 'modalQR':
                return (
                <Nav appearance="tabs" onSelect={onSelect} activeKey={tabActive}> 
                    <Nav.Item eventKey="batchInfo">{"Batch Info"}</Nav.Item>
                    <Nav.Item eventKey="qrInfo">{"QR Info"}</Nav.Item>
                </Nav>
                );
            case 'editbatch': 
                 return  <h4 style={{textAlign:'center'}}>{BP?.editformTitle}</h4>
            default: return <div />;

        }
    }

    const _getModalFooter = () => {
        switch(modalKey){
            case 'modalForm': 
                return (
                    <div style={{justifyContent:'center', display:'flex'}}>
                        <Button
                            onClick={onNextButtonClick} color={'yellow'}
                            style={{alignSelf:'center', marginTop:15}}
                            >{BP?.createBatchText}
                        </Button>
                    </div>
                    );
            case 'modalQR':
                if(tabActive === 'batchInfo'){
                    return(
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <ButtonV
                            containerStyle={{margin:10}} 
                            buttonTitle={BP?.editButtonTitle}
                            color={'yellow'}
                            buttonStyle={{justifyContent:"center", alignSelf:'center', marginTop:15}}
                            onPress={id=>{setModalKey('editbatch')}}
                            />
                        </div>
                    )
                }
                else {
                    return(
                        <div />
                //         <div style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                //     <Button
                //     onClick={onQrDownload} 
                //     color={'yellow'}
                //     style={{alignSelf:'center', margin:15}}
                //     >
                //         {BP?.downloadText}
                //     </Button>
                // </div>
                    )
                }
            case 'editbatch': 
                 return  (
                    <div style={{justifyContent:'center', display:'flex'}}>
                    <ButtonV
                    containerStyle={{margin:10}}  
                    appearance={'primary'}              
                    buttonTitle={BP?.updateBatchText} 
                    color={'yellow'}
                    buttonStyle={{alignSelf:'center', marginTop:15}}
                    onPress={onUpdateButtonPress}
                    />
                    </div>
                    )
            default: return <div />;
        }
    }

    const onUpdateButtonPress = () => {
        setValidateForm({...formContext})
    }

    const handleValidateResult = (
        formContext?: any,
        status?: 'success' | 'error',
        validationResult: any) => {
            let add_promoVideoUrl = defaultValuesDS.get('promoVideoURL')?.defaultValue;
            let add_promoWebsiteUrl = defaultValuesDS.get('redirectionURL')?.defaultValue;
            let add_promoEnabled= toggle;
            let contestButtonText = defaultValuesDS.get('button1Text')?.defaultValue;
            let promoButtonText = defaultValuesDS.get('button2Text')?.defaultValue;
            let formData = {
                add_promoWebsiteUrl,
                add_promoVideoUrl,
                add_promoEnabled,
                contestButtonText,
                promoButtonText
            }
            props?.onUpdateBatch && props?.onUpdateBatch(activeBatchKey, formData)
    }
    
    const _getModalBody = () => {
        if(modalKey === 'modalForm'){
            return (
                <>                
                <FormV
                    components={BP?.formData}
                    formContext={formContext}
                    validateForm={validateForm}
                    defaultValues={defaultValuesDS}
                    onValidateResult={formContext=>{
                }}
                />
                <View style={StyleSheet.flatten([styles.linkContainerStyle])}>
                    <Text style={{ textAlign: 'right', fontSize: 16, paddingTop: 5, paddingRight: 10 }}>
                       {BP?.promoSwitchText}
                     </Text>
                    <Toggle size="md"
                        onChange={onToggleChange}
                        style={{ marginTop: 5 }}
                        checked={toggle}
                    />
                </View>                 
                </>
            
            )
        }
        if(modalKey === 'modalQR'){
            return(
                <>
            {
                (tabActive === 'batchInfo') ? (
                    <FBGridV justify="start">
                        {_getBatchInfo()}
                        {/* <FBItemV colspan={24}>
                            
                        
                        </FBItemV>                         */}
                    </FBGridV>
                ):(
                    <>
                    <div style={{display:'flex', justifyContent:'center', marginTop:15, height:'100%'}} >
                    <iframe src={props?.getQRData || BP?.qrValue} height="100%" width="100%"></iframe>
                    {/* <QRCodeGeneratorV
                        containerStyle={StyleSheet.flatten([styles.qrStyle])}                        
                        size={250}
                        value={`${httpHost}${provenanceEP}${props?.activeGtin}`}
                    /> */}
                 </div>
                </>
                )
            }
            </>
            )
        }
        if(modalKey === 'editbatch'){
            let _videoUrl = Array.isArray(batchDetails) && batchDetails.find(item=>{
                if(item.key==='promoVideoUrl'){
                    return true
                }});
            let _redirectionUrl = Array.isArray(batchDetails) && batchDetails.find(item=>{
                if(item.key==='promoWebsiteUrl'){
                    return true
                }});
            let _isEnabled = Array.isArray(batchDetails) && batchDetails.find(item=>{
                if(item.key==='isPromoEnabled'){
                    return true
                }});
            let _button1Text = Array.isArray(batchDetails) && batchDetails.find(item => {
                if (item.key === 'contestButtonText') {
                    return true
                }
            });
            let _button2Text = Array.isArray(batchDetails) && batchDetails.find(item => {
                if (item.key === 'promoButtonText') {
                    return true
                }
            });
            let _enabled = (_isEnabled?.value === 'true') ? true: false;
            if(_isEnabled?.value === 'true'){          
            }

            let _editForm = Array.isArray(BP?.editformData) && BP?.editformData.map(item=>{
                if(item.key==='promoVideoURL'){
                    return({
                        ...item,
                        componentProps: {
                            containerStyle: {
                                width: "50%",
                                margin: 10
                            },
                            placeholder: _videoUrl?.value,
                            inline: false,
                            inside: false,
                            appearance: "liner",
                            color: "green"
                        }
                    })
                }
                if(item.key==='redirectionURL'){
                    return({
                        ...item,
                        componentProps: {
                            containerStyle: {
                                width: "50%",
                                margin: 10
                            },
                            placeholder: _redirectionUrl?.value,
                            inline: false,
                            inside: false,
                            appearance: "liner",
                            color: "green"
                        }
                    })
                }
                if (item.key === 'button1Text') {
                    return ({
                        ...item,
                        componentProps: {
                            containerStyle: {
                                width: "50%",
                                margin: 10
                            },
                            placeholder: _button1Text?.value,
                            inline: false,
                            inside: false,
                            appearance: "liner",
                            color: "green"
                        }
                    })
                }
                if (item.key === 'button2Text') {
                    return ({
                        ...item,
                        componentProps: {
                            containerStyle: {
                                width: "50%",
                                margin: 10
                            },
                            placeholder: _button2Text?.value,
                            inline: false,
                            inside: false,
                            appearance: "liner",
                            color: "green"
                        }
                    })
                }
                else return {}
            });
                return (
                    <>
                    <FormV
                    components={_editForm}
                    formContext={formContext}
                    validateForm={validateForm}
                    defaultValues={defaultValuesDS}
                    onValidateResult={handleValidateResult}
                    containerStyle={{ justifyContent: 'flex-start' }}
                    />
                    <View style={StyleSheet.flatten([styles.linkContainerStyle])}>
                        <Text style={{ textAlign: 'right', fontSize: 16, paddingTop: 5, paddingRight: 10 }}>
                           {BP?.promoSwitchText}
                         </Text>
                         
                         <Toggle size="md"
                         onChange={onToggleChange}
                         defaultChecked={_enabled}
                         style={{ marginTop: 5 }}
                        //  checked={toggle}
                        />                        
                    </View>
                    </>
                )         
        }
    }

    const onBreadcrumbsClick = (key:string) => {
        props.breadCrumbsClick && props.breadCrumbsClick(key)
    }
  
    const bodyContent = () => {
        let _batches = Array.isArray(props?.batches) && props?.batches.map(item=>{
            return ({
                key:item?.key,
                title: item?.title,
                subtitle1:null,
                tooltip:item?.tooltip,
                icon:item?.icon
            });
        });
        return (
          <CredentialTemplateV
            templateDetails={_batches}
            onCardClick={(eventKey:string, title: string) => onCardClick(eventKey, title)}
            containerStyle={StyleSheet.flatten([styles.containerStyle])}
          />
        );      
    }

    // const hederLeftData = () => {
    //   return (
    //     <div style={StyleSheet.flatten([styles.leftcontainerStyle])}>
    //       <Whisper
    //         placement="top"
    //         trigger="hover"
    //         speaker={<Tooltip>Search </Tooltip>}
    //         onClick={props.onSearchClick}
    //       >
    //         <IconButtonV icon={{ name: 'search' }} circle />
    //       </Whisper>
    //     </div>
    //   );
    // }

    const renderDrawerHeader = () => {
        return <div />;
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    const handleSubmit = (data: any) => {
        console.log(`data length`, data)
    }

    const renderDrawerBody = () => {
        return (
            <ScrollView>
                <View style={StyleSheet.flatten([styles.mainContainerStyle])}>
                    {data &&
                        <FormGeneratorV
                            // back_action="/"
                            // back_name="Back"
                            action_name="Submit"
                            form_action="/"
                            form_method="POST"
                            onSubmit={handleSubmit}
                            data={data && data}
                            //containerStyle={StyleSheet.flatten([styles.formContainerStyle])}
                            containerStyle={{ width: 500, borderWidth: 2, borderRadius: 10, borderColor: '#d2d3d3', paddingLeft: 40, paddingRight: 40, paddingBottom: 60, boxShadow: "1px 3px 1px #d2d3d3" }}
                            submitButtonContainerStyle={{ borderRadius: 6, backgroundColor: '#F05776', marginTop: 30 }}
                            backButtonContainerStyle={{ borderRadius: 6, marginTop: 30 }}
                            backButtonTextStyle={{ color: '#0073FF' }}
                            submitButtonTextStyle={{ color: '#fff', textAlign: 'center', alignSelf: 'center' }}
                        />
                    }
                </View>
            </ScrollView>
        );
    }

    return (
      <>
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
        <BodyTemplateV
          primaryColor={BP?.primaryColor || theme?.palette.primary}
          primaryHighlightColor={
            BP?.primaryHighlightColor || theme?.palette.secondary
          }
          secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
          ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
          renderContent={bodyContent}
          dashboardContainerStyle={StyleSheet.flatten([styles.containerStyle])}
          contentScrollableInline={true}
          headerTitleStyle={{ marginLeft: 20 }}
          breadcrumbs={BP?.breadcrumbs}
          onBreadcrumbsClick={(key)=>onBreadcrumbsClick(key)}
          navbarStyle={{}}
          layout={'sidebar-header-content-footer'}
          navBarRightItems={BP?.batchnavRightItems}
        //   navBarLeftItems={BP?.navLeftItems}
          onNavItemSelect={onNavItemClick}
          headerBackgroundColor={'#fff'}
          renderFooter={() => <div />}
        />
        <Modal show={show} onHide={onModalClose} style={StyleSheet.flatten([styles.modalStyle])}>
                <Modal.Header >
                    {_getModalHeader()}
                </Modal.Header>
                <Modal.Body style={{ height: 480 }}>

                    {_getModalBody()}
                </Modal.Body>
                <Modal.Footer>
                    {_getModalFooter()}
                </Modal.Footer>
        </Modal>
        <DrawerV
          placement={drawer.placement}
          show={drawer.show}
          backdrop={drawer.backdrop}
          size={drawer.size}
          renderHeader={renderDrawerHeader}
          onHide={handleOnDrawerHide}
          drawerTitle={drawer.title}
          renderBody={renderDrawerBody}
          renderFooter={renderDrawerFooter}
        />
      </>
    );
}
import { useMutation } from '@apollo/react-hooks';
import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useEffect, useState } from 'react';
import { pretty } from 'react-native-helpers';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import { LandingTemplate } from './components/LandingTemplate';
import { GET_STARTED } from './mutation';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
// import {useProductView} from '../ProductView/provider';
import Alert from 'rsuite/lib/Alert';
import config from '../../config/default.config.json';
import { Landing } from './components/LandingPage';
import { useAuth } from '../../services/auth-service/auth.provider';
import jwt_decode from 'jwt-decode';

// const console: LoggerService = new LoggerService(
//   new ConsoleLogger('LandingPage:container'),
// );

export const LandingPage = () => {
  const history = useHistory();
  const { signIn, getAuthToken, signOut } = useAuth();
  useEffect(() => {
    getAuthToken().then((token: any) => {
      if (token) {
        let decodedToken = jwt_decode(token);
        let currentDate = new Date();

        if(decodedToken?.exp * 1000 < currentDate.getTime()){
          //expired
          signOut();
          history.push('/')
        } else {
          //valid
          history.push('/dashboard');
        }
      }
      // setIsPreRendering(false);
    });
  })
  // const {getProvHomeLoading} = useProductView();
  useEffect(() => {
    const url = window.location.href;
    _getVerifyData(url);
    // if (res) {
    //   console.log('network and hash in useeffect', res.hash);
    //   const { network, hash } = res;
    //   if (network && hash) {
    //     // setHash(hash);
    //     config.hash = hash;
    //     getFullProvenanceBarcodeWeb({ variables: { network: network, hash: hash } })
    //   }
    // }
    ReactGA.initialize(config.gaTrackerID);
    ReactGA.pageview('/');
  }, []);

  // useEffect(()=>{
  //   if(provData){
  //     history.push('/productView')
  //   }
  // }, [provData]);

  const handleGetStarted = () => {
    Alert.success("Thank you for your interest. We will get back to you soon!", 5000);
  }

  const _getVerifyData = (url: string) => {
    let param = url.split("/");
    let _params = param.slice(-2);
    if (_params && Array.isArray(_params) && param.length > 5) {
      return history.push(`/provenance/${_params[0]}/${_params[1]}`);
    }
    return history.push("/");
  }

  const _renderBody = () => {
    // if(getProvHomeLoading){
    //   return <LogoSpinnerV uri={'https://i.ibb.co/fkhtppW/400x400-white.png'} />
    // }
    // else {
    ReactGA.event({
      category: "LandingPage",
      action: "LandingPage: onRenderLandingPage"
    })
    return <Landing />;
    // <LandingTemplate
    //   onLoginClick={() => history.push('/login')}
    //   // onSignupClick={() => history.push('/signup')}
    //   onGetStarted={handleGetStarted}
    // />
    // }

  };
  return <>{_renderBody()}</>;
};
